import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context';

export default function LoginCallback() {
  const navigate = useNavigate();

  const { isAuthenticated, isLoading, isAdmin, error } = useUserContext();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      navigate(isAdmin ? '/admin/dashboard' : '/manager/dashboard');
    }
  }, [isLoading, isAuthenticated, isAdmin, navigate]);

  if (error)
    return (
      <div>{JSON.stringify(error)}</div>
    )
  return null;
}
