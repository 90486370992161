import React from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';
import { MdOutlineBedroomChild } from "react-icons/md";


export default function RoomBookingCard({ roomDetails }) {
    function getDateDifference(dateString1, dateString2) {
        const [day1, month1, year1, hour1, minute1] = dateString1.split(/[\s:-]/);
        const [day2, month2, year2, hour2, minute2] = dateString2.split(/[\s:-]/);

        const date1 = new Date(year1, month1 - 1, day1, hour1 || 0, minute1 || 0);
        const date2 = new Date(year2, month2 - 1, day2, hour2 || 0, minute2 || 0);

        const differenceMs = Math.abs(date2 - date1);

        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

        if (differenceDays === 0) {
            return 1;
        } else {
            return differenceDays;
        }
    }
    return (
        <Tooltip
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
            }}
            content={
                <table className="min-w-full divide-y text-blue-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room#</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check In</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Check Out</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Days</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y ">
                        {roomDetails.map((roomDetail, index) => (
                            <tr key={index}>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.roomNo}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.checkInDateTime}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{roomDetail.checkOutDateTime}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{getDateDifference(roomDetail.checkInDateTime, roomDetail.checkOutDateTime)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal flex flex-col cursor-pointer"
            >
                {roomDetails.map(roomDetail => (
                    <div className="flex flex-row text-blue-gray-500 mt-2" key={roomDetail.roomNo}>
                        <MdOutlineBedroomChild className="h-6 w-6" />
                        <Typography key={roomDetail.roomNo} variant="small" className="pl-2 pt-0 underline">{roomDetail.roomNo}</Typography>
                    </div>
                ))}
            </Typography>
        </Tooltip>
    )
}
