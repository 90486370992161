
const API_URL = process.env.REACT_APP_API_SERVER_URL;

const doFetch = async function (request) {
    const res = await fetch(request);
    if (res.ok) {
        return res;
    }
    if (res.status >= 400 && res.status < 500) {
        throw new Error(`CLIENT_ERROR`);
    } else if (res.status >= 500 && res.status < 600) {
        throw new Error('SERVER_ERROR');
    } else {
        throw new Error('Unexpected Error: Something went wrong.');
    }
};

export const useApi = function () {

    const getUserAssignments = async function (accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/user/assignments`, {
            headers: headers
        });

        const res = await doFetch(request);
        return res.json();
    }

    const getHotels = async function (accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/user/hotels`, {
            headers: headers
        });

        const res = await doFetch(request);
        return res.json();
    }

    const updateHotel = async function (hotelId, hotel, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}`, {
            headers: headers,
            method: 'PUT',
            body: JSON.stringify(hotel)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const addRoomType = async function (hotelId, roomType, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/roomTypes`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(roomType)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const deleteRoomType = async function (hotelId, category, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/roomTypes/${btoa(category)}`, {
            headers: headers,
            method: 'DELETE',
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const addRoom = async function (hotelId, room, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/rooms`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(room)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');

        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const deleteRoom = async function (hotelId, roomNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/rooms/${btoa(roomNo)}`, {
            headers: headers,
            method: 'DELETE',
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getManagers = async function (hotelIds, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${btoa(hotelIds)}/managers`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const uploadDocument = async function (imageData, accessToken) {
        const base64Data = imageData.replace(/^data:image\/\w+;base64,/, '');

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'text/plain'
        });
        const formData = new FormData();
        formData.append('text', base64Data);
        const request = new Request(`${API_URL}/upload`, {
            headers: headers,
            method: 'POST',
            body: base64Data
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const addGuest = async function (hotelId, guestData, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/guests`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(guestData)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const searchGuest = async function (hotelId, mobile, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/guests/${mobile}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getNextCardNo = async function (hotelId, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/nextCardNo`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getAvailableRooms = async function (hotelId, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/availableRooms`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCheckedInRooms = async function (hotelId, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/checkedInRooms`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const checkInRooms = async function (hotelId, checkInCard, cardNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/checkInRooms/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(checkInCard)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCardNos = async function (hotelId, guestMobile, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/cardNos/${guestMobile}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCheckInCard = async function (hotelId, cardNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/checkInRooms/${cardNo}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const checkOutRooms = async function (hotelId, cardNo, checkOutCard, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/checkOutRooms/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(checkOutCard)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const updateCheckOutRooms = async function (hotelId, cardNo, checkOutCard, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/updatedCheckOutCard/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(checkOutCard)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const updateCheckInRooms = async function (hotelId, cardNo, checkInCard, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/checkInRooms/${cardNo}`, {
            headers: headers,
            method: 'PUT',
            body: JSON.stringify(checkInCard)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCards = async function (hotelId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/cards/range/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCard = async function (hotelId, cardNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/getCard/${cardNo}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        const contentLength = parseInt(res.headers.get('content-length') || '0');
        if (contentLength === 0) {
            console.log("Empty response received.");
            return null;
        }
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getPayments = async function (hotelId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/payments/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const collectAdvance = async function (hotelId, cardNo, paymentDetail, documentS3Id, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            "document-id": documentS3Id
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/collectAdvance/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(paymentDetail)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const transferRoom = async function (hotelId, cardNo, shiftRequest, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/transferRoom/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(shiftRequest)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getImageUrl = async function (documentS3Id, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/image/${documentS3Id}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getExpenses = async function (hotelId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/expenses/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getIncomes = async function (hotelId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/incomes/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const saveExpense = async function (hotelId, expense, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/expenses`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(expense)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const saveIncome = async function (hotelId, income, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/incomes`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(income)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const singleCheckout = async function (hotelId, cardNo, roomDetail, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/singleCheckout/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(roomDetail)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const extendRoom = async function (hotelId, cardNo, checkIn, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/extendRoom/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(checkIn)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const markCardsClose = async function (hotelId, cardNos, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/cardClose`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(cardNos)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const deleteExpense = async function (hotelId, expenseDateTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/expenses/${expenseDateTime}`, {
            headers: headers,
            method: 'DELETE'
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCardsByStatus = async function (hotelId, status, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/cards/status/${status}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCardsByCSV = async function (hotelId, cardNos, accessToken) {
        let cardNoCSV = cardNos.join(',');
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });
        const request = new Request(`${API_URL}/hotel/${hotelId}/cards/csv/${cardNoCSV}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const deletePayment = async function (hotelId, paymentDateTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/paymentAudit/${paymentDateTime}`, {
            headers: headers,
            method: 'DELETE'
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const saveTaxInvoice = async function (hotelId, taxInvoice, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/taxInvoice`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(taxInvoice)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getTaxInvoiceByCardNo = async function (hotelId, cardNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/taxInvoice/${cardNo}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        const contentLength = parseInt(res.headers.get('content-length') || '0');
        if (contentLength === 0) {
            console.log("Empty response received.");
            return null;
        }
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getNextTaxInvoiceNo = async function (hotelId, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/nextTaxInvoiceNo`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getTaxInvoicesByMobile = async function (hotelId, guestMobile, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/taxInvoices/${guestMobile}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getTaxInvoicesByRange = async function (hotelId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/taxInvoicesByRange/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getAttendances = async function (hotelId, employeeId, startTime, endTime, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/attendance/${employeeId}/${startTime}/${endTime}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getEmployees = async function (hotelId, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/employees`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const saveEmployee = async function (hotelId, employee, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/employees`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(employee)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const logAttendance = async function (hotelId, attendance, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/attendance`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(attendance)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const addCheckIn = async function (hotelId, cardNo, checkIn, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/addCheckIn/${cardNo}`, {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(checkIn)
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const downloadTaxInvoice = async function (hotelId, cardNo, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/pdf'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/download/taxInvoice/${cardNo}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "TaxInvoice_" + cardNo + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }

    const getProfitDetails = async function (start, end, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/profit/${start}/${end}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const downloadTaxInvoices = async function (hotelId, start, end, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'vnd.ms-excel'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/download/taxInvoices/${start}/${end}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "TaxInvoice.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }


    const downloadCards = async function (hotelId, start, end, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/pdf'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/download/cards/${start}/${end}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Cards_" + hotelId + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }

    const getNextEmployeeNo = async function (hotelId, accessToken) {

        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/nextEmployeeNo`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const getCardsByMobile = async function (hotelId, guestMobile, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/cardsByMobile/${guestMobile}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }

    const downloadTransactions = async function (hotelId, start, end, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/pdf'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/download/transactions/${start}/${end}`, {
            headers: headers
        });

        const res = await doFetch(request);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = "Transactons_" + hotelId + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    }

    const deleteAttendance = async function (hotelId, employeeId, logdate, accessToken) {
        const headers = new Headers({
            authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        });

        const request = new Request(`${API_URL}/hotel/${hotelId}/attendance/${employeeId}/${logdate}`, {
            headers: headers,
            method: 'DELETE'
        });

        const res = await doFetch(request);
        const contentType = res.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
            return res.json();
        } else {
            return res.text();
        }
    }



    return {
        getUserAssignments, getHotels, updateHotel,
        addRoomType, deleteRoomType, addRoom, deleteRoom,
        getManagers, uploadDocument, addGuest, searchGuest,
        getNextCardNo, getAvailableRooms, checkInRooms, getCardNos,
        checkOutRooms, getCheckInCard, updateCheckInRooms,
        getCheckedInRooms, getCards, getCard, getPayments,
        collectAdvance, transferRoom, updateCheckOutRooms,
        getImageUrl, getExpenses, saveExpense, getIncomes,
        saveIncome, singleCheckout, extendRoom, markCardsClose,
        deleteExpense, getCardsByStatus, getCardsByCSV,
        deletePayment, saveTaxInvoice, getTaxInvoiceByCardNo,
        getNextTaxInvoiceNo, getTaxInvoicesByMobile, getTaxInvoicesByRange,
        saveEmployee, getEmployees, logAttendance, getAttendances, addCheckIn,
        downloadTaxInvoice, getProfitDetails, downloadTaxInvoices, downloadCards,
        getNextEmployeeNo, getCardsByMobile, downloadTransactions, deleteAttendance
    };
};