import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";
import {
  Button,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import { useMaterialTailwind, setOpenSidenav, useUserContext } from '../../context';

const sidenavTypes = {
  dark: "bg-gradient-to-br from-gray-800 to-gray-900",
  white: "bg-white shadow-sm",
  transparent: "bg-transparent",
};

function Sidenav({ brandImg, brandName, routes }) {

  const { logout, isAuthenticated, isLoading, assignments } = useUserContext();
  const [controller, dispatch] = useMaterialTailwind();
  const { sidenavColor, sidenavType, openSidenav } = controller;

  const [roles, setRoles] = useState(["MANAGER"]);
  useEffect(() => {
    if (!isLoading && isAuthenticated && assignments && assignments.roles) {
      setRoles(assignments.roles);
    }
  }, [isLoading, isAuthenticated, assignments]);

  return (
    <aside
      className={`${sidenavTypes[sidenavType]} ${openSidenav ? "translate-x-0" : "-translate-x-80"
        } fixed inset-0 z-50 my-4 ml-4 h-[calc(100vh-32px)] w-60 rounded-xl transition-transform duration-300 xl:translate-x-0 border border-blue-gray-100 overflow-y-auto`}  >
      <div className={`relative`} >
        <Link to="/" className="py-6 px-8 text-center">
          <Typography
            variant="h5"
            color={sidenavType === "dark" ? "white" : "blue-gray"}
          >
            {brandName}
          </Typography>
        </Link>
        <IconButton
          variant="text"
          color="black"
          size="sm"
          ripple={false}
          className="absolute top-5 right-5 grid rounded-br-none rounded-tl-none lg:hidden"
          onClick={() => setOpenSidenav(dispatch, false)}
        >
          <IoIosCloseCircle size={36} className="cursor-pointer" />
        </IconButton>
      </div>
      <div className="m-4">
        {routes.map(({ layout, title, pages, role }, key) => roles.includes(role) && (
          <ul key={key} className="mb-2 flex flex-col gap-1">
            {title && (
              <li className="mx-3.5 mt-2 mb-2">
                <Typography
                  variant="small"
                  color={sidenavType === "dark" ? "white" : "blue-gray"}
                  className="font-black uppercase opacity-75"
                >
                  {title}
                </Typography>
              </li>
            )}
            {pages.map(({ icon, name, path, isNavLink }) => (
              isNavLink && <li key={name}>
                <NavLink to={`/${layout}${path}`}>
                  {({ isActive }) => (
                    <Button
                      variant={isActive ? "gradient" : "text"}
                      color={
                        isActive
                          ? sidenavColor
                          : sidenavType === "dark"
                            ? "white"
                            : "blue-gray"
                      }
                      className="flex items-center gap-4 px-4 capitalize"
                      fullWidth onClick={() => setTimeout(() => setOpenSidenav(dispatch, false), 100)}
                    >
                      {icon}
                      <Typography
                        color="inherit"
                        className="font-medium capitalize"
                      >
                        {name}
                      </Typography>
                    </Button>
                  )}
                </NavLink>
              </li>
            ))}
            <hr className="my-2 border-blue-gray-50" />
          </ul>

        ))}
      </div>
      <div className="mt-2 ml-6 mr-4 mb-2 ">
        <Button variant="outlined" color="red" className="flex items-center gap-3" onClick={() => logout({ returnTo: window.location.origin })}>
          Logout{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
            />
          </svg>
        </Button>
      </div>
    </aside>
  )
}

Sidenav.defaultProps = {
  brandImg: "/img/logo-ct.png",
  brandName: "InnPro",
};

Sidenav.propTypes = {
  brandImg: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Sidenav.displayName = "/src/widgets/layout/sidenav.js";

export default React.memo(Sidenav);
