import { IconButton } from '@material-tailwind/react';
import React, { useState, useEffect } from 'react';
import { MdOutlineWbSunny } from "react-icons/md";
import { MdSunny } from "react-icons/md";
import { PiMoonFill } from "react-icons/pi";
import { PiMoonLight } from "react-icons/pi";

const Calendar = ({ assignments, dayData, nightData, onDateSelected, selectedMonth, onMonthChange }) => {
  const [displayedMonth, setDisplayedMonth] = useState(selectedMonth);

  useEffect(() => {
    setDisplayedMonth(selectedMonth);
  }, [selectedMonth]);

  // Get the month and year details
  const currentMonth = displayedMonth.getMonth();
  const currentYear = displayedMonth.getFullYear();
  const firstDay = new Date(currentYear, currentMonth, 1);
  const lastDay = new Date(currentYear, currentMonth + 1, 0);
  const daysInMonth = lastDay.getDate();

  // Weekday names
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const leadingBlanks = firstDay.getDay();
  const daysArray = Array.from({ length: leadingBlanks + daysInMonth }, (_, index) => {
    const day = index - leadingBlanks + 1;
    //return day > 0 ? day : null;
    if (day > 0) {
      const date = new Date(currentYear, currentMonth, day);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
      const dayOfMonth = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${dayOfMonth}`; // Formats as "yyyy-MM-dd" in local time (IST)
    }
    return null;
  });

  const handleDayClick = (day, shift) => {
    if (day) {
      let existingDetails = undefined;
      if (shift === "DAY") {
        existingDetails = dayData.find(d => d.date === day)
      }
      if (shift === "NIGHT") {
        existingDetails = nightData.find(d => d.date === day)
      }
      onDateSelected(day, shift, existingDetails);
    }
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(currentYear, currentMonth - 1, 1);
    setDisplayedMonth(newDate);
    onMonthChange(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(currentYear, currentMonth + 1, 1);
    setDisplayedMonth(newDate);
    onMonthChange(newDate);
  };

  function getDayPart(dateString) {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    return date.getDate();
  }

  function getDayShift(dateString) {
    return dayData.find(d => d.date === dateString);
  }

  function getNightShift(dateString) {
    return nightData.find(d => d.date === dateString);
  }
  const today = new Date();
  const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  return (
    <div className="p-5">
      <div className="flex justify-between items-center mb-4">
        <button onClick={handlePreviousMonth} className="px-3 py-1 bg-green-300 rounded hover:bg-green-500">
          {"<<<"}
        </button>
        <h2 className="text-2xl font-bold text-center">
          {firstDay.toLocaleString('default', { month: 'long' })} {currentYear}
        </h2>
        <button onClick={handleNextMonth} className="px-3 py-1 bg-green-300 rounded hover:bg-green-500">
          {">>>"}
        </button>
      </div>

      <div className="grid grid-cols-7 gap-4 mb-2 text-center font-semibold">
        {weekDays.map((week) => (
          <div key={week} className="text-gray-600">
            {week}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-7 gap-4">
        {daysArray.map((day, index) => (
          <div
            key={index}
            className={`border rounded-lg p-4 flex flex-col items-center ${day ? '' : 'bg-gray-100'}`} >
            {day ? (
              <>
                <span className={`text-m font-semibold select-none flex items-center justify-center h-10 w-10 rounded-full ${day === todayString ? 'bg-blue-500 text-white' : ''
                  }`}>{getDayPart(day)}</span>
                <div className="flex flex-col items-center space-x-0 space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0 mt-2">
                  {getDayShift(day) && <div className="flex flex-col items-center">
                    <IconButton variant="text" onClick={() => day && handleDayClick(day, "DAY")} >
                      <MdSunny className="text-red-500" size={20} />
                    </IconButton>
                  </div>}
                  {!getDayShift(day) && <div className="flex flex-col items-center">
                    <IconButton variant="text" onClick={() => day && handleDayClick(day, "DAY")} >
                      <MdOutlineWbSunny size={20} />
                    </IconButton>
                  </div>}
                  {getNightShift(day) && <div className="flex flex-col items-center">
                    <IconButton variant="text" onClick={() => day && handleDayClick(day, "NIGHT")} >
                      <PiMoonFill className="text-black-500" size={20} />
                    </IconButton>
                  </div>}

                  {!getNightShift(day) && <div className="flex flex-col items-center">
                    <IconButton variant="text" onClick={() => day && handleDayClick(day, "NIGHT")} >
                      <PiMoonLight className="text-white-500" size={20} />
                    </IconButton>
                  </div>}

                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>
    </div >
  );
};

export default Calendar;
