import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Chip,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    Checkbox,
    Typography
} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';


const TABLE_HEAD = ["S.No", "Employee", "Role", "Status", "* Primary", "Rate", "Address"];

export default function Employees() {

    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const { getEmployees, saveEmployee, getNextEmployeeNo, getImageUrl } = useApi();
    const { fetchAccessToken, defaultHotel, assignments } = useUserContext();
    const [showAddEmployeePopup, setShowAddEmployeePopup] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const [isShowProofImage, setShowProofImage] = useState(false);
    const [employeeName, setEmployeeName] = useState("");
    const [employeeMobile, setEmployeeMobile] = useState("");
    const [primaryLocation, setPrimaryLocation] = useState("");
    const [employeeRole, setEmployeeRole] = useState("");
    const [employeeAddress, setEmployeeAddress] = useState("");
    const [employeeWage, setEmployeeWage] = useState(0);

    useEffect(() => {
        let getEmployeesInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getEmployees(hotelId, token);
                if (data && data.length) {
                    setEmployees(data);
                } else {
                    setEmployees([]);
                }
            } catch (ex) {
                setEmployees([]);
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getEmployeesInternal(defaultHotel);
        }

    }, [defaultHotel]);

    let handleProofView = async (documentS3Id) => {
        try {
            setIsLoading(true);
            const token = await fetchAccessToken();
            const imageUrl = await getImageUrl(documentS3Id, token);
            if (imageUrl) {
                setImageUrl(imageUrl);
                setShowProofImage(true);
            }
        } catch (ex) {
            alert("Error occurred while viewing the Proof.");
        } finally {
            setIsLoading(false);
        }
    };

    let addEmployeeInternal = async () => {
        if (!defaultHotel || !employeeName || !employeeMobile || !primaryLocation || !employeeAddress || !employeeRole || employeeWage <= 0) {
            return;
        }
        setShowAddEmployeePopup(false);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let data = await getNextEmployeeNo(defaultHotel, token);
            if (!data) {
                alert("Something went wrong.")
            }

            const payload = {
                id: "E" + data.nextNo,
                name: employeeName,
                mobile: employeeMobile,
                role: employeeRole,
                primaryLocation: primaryLocation,
                address: employeeAddress,
                status: "ACTIVE",
                dayRate: employeeWage
            }
            await saveEmployee(defaultHotel, payload, token);
            await getEmployeesInternal(defaultHotel);
            setEmployeeName("");
            setEmployeeMobile("");
            setEmployeeRole("");
            setEmployeeAddress("");
        } catch (ex) {
            setEmployees([]);
        } finally {
            setIsLoading(false);
        }
    };


    let getEmployeesInternal = async (hotelId) => {
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const data = await getEmployees(hotelId, token);
            if (data && data.length) {
                setEmployees(data);
            } else {
                setEmployees([]);
            }
        } catch (ex) {
            setEmployees([]);
        } finally {
            setIsLoading(false);
        }
    }

    function getHotelName(hotelid) {
        let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelid.toLowerCase());
        if (hotel) {
            return hotel.name;
        }
        return "Other Hotel";
    }

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Employees
                    </Typography>
                    <div>
                        <button className="shadow bg-purple-500 hover:bg-purple-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4 mr-4 rounded" type="button"
                            onClick={() => setShowAddEmployeePopup(true)} >
                            Add Employee
                        </button>
                    </div>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map(
                                ({ id, name, mobile, primaryLocation, role, status, address, dayRate }, index) => {
                                    const isLast = index === employees.length - 1;
                                    const classes = isLast
                                        ? "p-2"
                                        : "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {name}
                                                    </Typography>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        <a href={`tel:${mobile}`} style={{ textDecoration: 'underline' }}>{mobile}</a>
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {role}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Chip
                                                    variant="gradient"
                                                    color={status === "ACTIVE" ? "green" : "blue-gray"}
                                                    value={status === "ACTIVE" ? "active" : "blocked"}
                                                    className="py-0.5 px-2 text-[11px] font-medium w-fit"
                                                />
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {getHotelName(primaryLocation)}
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {dayRate}.00
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {address}
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            <Dialog open={showAddEmployeePopup}>
                <DialogHeader>Add Employee</DialogHeader>
                <DialogBody>
                    <div className="grid grid-cols-2 gap-2" >
                        <div className="w-50 mr-3 mt-3">
                            <label htmlFor="employeeName" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Name <span className="text-red-500">*</span></label>
                            <input type="text" id="employeeName" name="employeeName"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={employeeName} onChange={(event) => setEmployeeName(event.target.value)} />
                        </div>
                        <div className="w-50 mr-3 mt-3">
                            <label htmlFor="employeeMobile" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Mobile  <span className="text-red-500">*</span></label>
                            <input type="tel" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={employeeMobile} onChange={(event) => setEmployeeMobile(event.target.value)} />
                        </div>
                        <div className="w-50 mr-3 mt-3">
                            <label htmlFor="primaryLocation" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Primary Hotel <span className="text-red-500">*</span></label>
                            <select color="blue" id="primaryLocation" name="primaryLocation" label="primaryLocation" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                onChange={(event) => setPrimaryLocation(event.target.value)} value={primaryLocation}>
                                <option key="" value={""}>Select</option>
                                {assignments?.hotels.map((hotel) => (
                                    <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-50 mr-3 mt-3">
                            <label htmlFor="employeeRole" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Role <span className="text-red-500">*</span></label>
                            <select color="blue" id="employeeRole" name="employeeRole" label="employeeRole" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                onChange={(event) => setEmployeeRole(event.target.value)} value={employeeRole}>
                                <option value={""}>Select</option>
                                <option value="Manager">Manager</option>
                                <option value="House Keeping">House Keeping</option>
                                <option value="Service Boy">Service Boy</option>
                                <option value="Supervisor">Supervisor</option>
                                <option value="Accountant">Accountant</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="w-25 mr-3 mt-3">
                            <label htmlFor="employeeWage" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Per Shift Wage <span className="text-red-500">*</span></label>
                            <input type="number" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={employeeWage} onChange={(event) => setEmployeeWage(parseInt(event.target.value, 10))} />
                        </div>
                        <div className="w-75 mr-3 mt-3 col-span-2">
                            <label htmlFor="employeeAddress" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Address <span className="text-red-500">*</span></label>
                            <textarea id="employeeAddress" name="employeeAddress" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="3" cols="50"
                                onChange={(event) => setEmployeeAddress(event.target.value)} value={employeeAddress} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowAddEmployeePopup(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={addEmployeeInternal}>
                        <span>Save</span>
                    </Button>
                </DialogFooter>
            </Dialog>
            <Dialog size={"xs"} open={isShowProofImage}>
                <DialogHeader className="flex justify-end items-center h-full">
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogHeader>
                <DialogBody>
                    <img src={imageUrl} />
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowProofImage(false)}
                        className="mr-1"
                    >
                        <span>Close</span>
                    </Button>
                </DialogFooter>
            </Dialog>
        </div>
    )
}
