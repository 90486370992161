import React, { useState, useRef } from 'react';
import {
    IconButton,
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    Typography,
    CardFooter,
    Button,
    Checkbox
} from "@material-tailwind/react";
import { MdDelete } from "react-icons/md";
import { useApi } from '../../hooks/useApi';
import { useUserContext } from '../../context';
import { ClockLoader } from 'react-spinners';
import { useNavigate, useLocation } from 'react-router-dom';

export default function EditExpense() {
    let { expenseType, expenseAmount, expenseDate, expenseRemarks, expenseDateTime, documentS3Ids, cratedBy, cash } = useLocation().state || {};
    const [type, setType] = useState(expenseType || "");
    const [remarks, setRemarks] = useState(expenseRemarks || "");
    const [amount, setAmount] = useState(expenseAmount || 0);
    const [isCash, setIsCash] = useState(cash);
    const [date, setDate] = useState(() => {
        if (expenseDateTime) {
            const date = new Date(expenseDateTime);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${year}-${month}-${day}`;
        }
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    });
    const fileInputRef = useRef(null);
    const [attachments, setAttachments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { uploadDocument, saveExpense, deleteExpense } = useApi();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const navigate = useNavigate();

    let handleSubmitInternal = async () => {
        if (type && amount > 0 && date) {
            setIsLoading(true);
            const token = await fetchAccessToken();
            if (expenseDateTime) {
                deleteExpense(defaultHotel, expenseDateTime, token);
            }
            const docS3Ids = await Promise.all(attachments.map(async (attachment) => {
                let base64String = await convertBlobToBase64(attachment);
                return await uploadDocument(base64String, token);
            }));
            documentS3Ids = documentS3Ids.concat(docS3Ids);
            expenseDateTime = getEpoch(date);
            expenseDate = getDateAndTime(date)
            try {
                const expenseRequest = {
                    expenseType: type,
                    expenseAmount: amount,
                    expenseDate: expenseDate,
                    expenseDateTime: expenseDateTime,
                    documentS3Ids: documentS3Ids,
                    remarks: remarks,
                    user: cratedBy,
                    cash: isCash
                };
                await saveExpense(defaultHotel, expenseRequest, token);
                alert("Expense updated.");
                navigate("/manager/expenses");
            } catch (ex) {
                alert("Something went wrong");
            }
            finally {
                setIsLoading(false);
            }
        }
    };

    const handleDeleteAttachment = (index) => {
        const newItems = attachments.filter((_, i) => i !== index);
        setAttachments(newItems);
    };


    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    function getEpoch(date) {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function getDateAndTime(date) {
        const now = new Date();
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);

        const formattedDateTime = `${combinedDate.getDate().toString().padStart(2, '0')}-${(combinedDate.getMonth() + 1).toString().padStart(2, '0')}-${combinedDate.getFullYear()} ${combinedDate.getHours().toString().padStart(2, '0')}:${combinedDate.getMinutes().toString().padStart(2, '0')}`;
        return formattedDateTime;
    }

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            compressImage(file, (compressedBlob) => {
                setAttachments([...attachments, compressedBlob]);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            });
        } else {
            alert('Please upload a JPEG or PNG image.');
        }
    };

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const fileType = file.type;
                const isJPEG = fileType === 'image/jpeg' || fileType === 'image/jpg';

                let quality = isJPEG ? 1.0 : undefined;
                const maxFileSize = 1000000; // 1MB

                const compress = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size <= maxFileSize || (isJPEG && quality < 0.1)) {
                            callback(blob);
                        } else if (isJPEG) {
                            quality -= 0.1;
                            compress();
                        } else {
                            // For PNG, if the first compression is too large, we can't reduce quality, so we just use the first result
                            callback(blob);
                        }
                    }, fileType, quality);
                };

                compress();
            };
        };
        reader.readAsDataURL(file);
    };

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Edit Expense
                    </Typography>
                </CardHeader>
                <CardBody>
                    <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="expenseType" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Expense Type <span className="text-red-500">*</span></label>
                            <select label="expenseType" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                name="paymentMode" id="paymentMode"
                                onChange={(event) => setType(event.target.value)}
                                value={type}>
                                <option key="" value="">Select</option>
                                <option key="Electricity" value="Electricity">Electricity</option>
                                <option key="Rent" value="Rent">Rent</option>
                                <option key="Salaries" value="Salaries">Salaries</option>
                                <option key="Recharge" value="Recharge">Recharge</option>
                                <option key="Internet" value="Internet">Internet</option>
                                <option key="Repairs" value="Repairs">Repairs</option>
                                <option key="Food" value="Food">Food</option>
                                <option key="Supplies" value="Supplies">Supplies</option>
                                <option key="Taxes" value="Taxes">Taxes</option>
                                <option key="Software" value="Software">Software</option>
                                <option key="Cash Collect" value="Cash Collect">Cash Collect</option>
                                <option key="Others" value="Others">Others</option>
                            </select>
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="amount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount<span className="text-red-500">*</span></label>
                            <input type="number" id="amount" name="amount"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={amount} onChange={(event) => setAmount(parseInt(event.target.value, 10))} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="date" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Cash Transaction?<span className="text-red-500">*</span></label>
                            <Checkbox checked={isCash} onChange={(event) => setIsCash(event.target.checked)} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="date" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Date<span className="text-red-500">*</span></label>
                            <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={date} onChange={(event) => setDate(event.target.value)} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="remarks" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Remarks</label>
                            <input type="text" id="remarks" name="remarks"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={remarks} onChange={(event) => setRemarks(event.target.value)} />
                        </div>
                        <div className="flex flex-col mr-3 mt-3 mb-3">
                            <label htmlFor="proof" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Proofs</label>
                            <div className="flex flex-col lg:w-4/12">
                                <div className="py-3">
                                    <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} />
                                </div>
                            </div>
                            <div className="relative w-full px-5">
                                {
                                    attachments.map((image, i) =>
                                        <div className="flex flex-row">
                                            <img key={i} src={URL.createObjectURL(image)} style={{ height: "100px", width: "100px", margin: "5px" }} />
                                            <IconButton variant="text" onClick={() => handleDeleteAttachment(i)}>
                                                <MdDelete className="h-6 w-6" />
                                            </IconButton>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                        <span>Save</span>
                    </Button>
                </CardFooter>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    )
}
