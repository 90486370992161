import React from 'react';
import { Route, Routes } from "react-router-dom";
import { useMaterialTailwind, useUserContext } from '../context';
import routes from "../routes";
import { Sidenav, Topnav } from '../widgets/layout';
import { ClockLoader } from 'react-spinners';


function Admin() {
    const [controller, dispatch] = useMaterialTailwind();
    const { sidenavType, isLoading } = controller;
    const { isAdmin } = useUserContext();
    return (
        <div className="min-h-screen bg-blue-gray-50/50">
            <Sidenav brandName={isAdmin ? "Administrator" : "Manager"}
                routes={routes}
                brandImg={
                    sidenavType === "dark" ? "/img/logo-ct.png" : "/img/logo-ct-dark.png"
                }
            />
            {isLoading ?
                (
                    <div className="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
                        <div className="flex justify-center items-center mt-[50vh]">
                            <ClockLoader color="#2be60d" />
                        </div>
                    </div>
                ) :
                (
                    <div className="p-4 xl:ml-60">
                        <Topnav />
                        <Routes>
                            {routes.map(
                                ({ layout, pages }) =>
                                    layout === "admin" &&
                                    pages.map(({ path, element }) => (
                                        <Route exact path={path} element={element} />
                                    ))
                            )}
                        </Routes>
                    </div>
                )
            }
        </div>
    )
}

export default React.memo(Admin);