import { IoHome } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
import { FaHotel } from "react-icons/fa";
import { ImUser } from "react-icons/im";
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { ImExit } from "react-icons/im";
import { ImEnter } from "react-icons/im";
import { FaFileInvoice } from "react-icons/fa6";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { FaMoneyBillWave } from "react-icons/fa";
import { FaFileDownload } from "react-icons/fa";
import { GiNotebook } from "react-icons/gi";
import { FaBook } from "react-icons/fa";
import { GiLoincloth } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";
import { GrUserWorker } from "react-icons/gr";
import AdminDashboard from './pages/admin/dashboard';
import HotelStats from "./pages/admin/hotel-stats";
import Hotels from "./pages/admin/hotels";
import HotelDetails from "./pages/admin/hotel-details";
import Rooms from "./pages/admin/rooms";
import Managers from "./pages/admin/managers";
import Reports from "./pages/admin/reports";
import ManagerDashboard from "./pages/manager/dashboard";
import Guests from "./pages/manager/guests";
import CheckIn from "./pages/manager/check-in"
import Checkout from "./pages/manager/check-out";
import Cards from "./pages/manager/cards";
import CardDetails from "./pages/manager/card-details";
import Expense from "./pages/manager/expenses";
import AddExpense from "./pages/manager/add-expense";
import CashTracker from "./pages/manager/cash-tracker";
import EditExpense from "./pages/manager/edit-expense";
import Downloads from "./pages/admin/downloads";
import TaxInvoices from "./pages/manager/tax-invoices";
import Attendance from "./pages/manager/attendance";
import Dhobi from "./pages/manager/dhobi";
import Complaints from "./pages/manager/complaints";
import Employees from "./pages/admin/employees";

const icon = {
    className: "w-5 h-5 text-inherit",
};

const routes = [
    {
        title: "Administrator",
        layout: "admin",
        role: "ADMIN",
        pages: [
            {
                icon: <IoHome {...icon} />,
                name: "Dashboard",
                path: "/dashboard",
                element: <AdminDashboard />,
                isNavLink: true
            },
            {
                icon: <IoHome {...icon} />,
                name: "Dashboard",
                path: "/dashboard/stats/:hotelId",
                element: <HotelStats />,
                isNavLink: false
            },
            {
                icon: <FaHotel {...icon} />,
                name: "Hotels",
                path: "/hotels",
                element: <Hotels />,
                isNavLink: true
            },
            {
                icon: <FaHotel {...icon} />,
                name: "Hotels",
                path: "/hotels/:hotelId",
                element: <HotelDetails />,
                isNavLink: false
            },
            {
                icon: <BiSolidReport {...icon} />,
                name: "Reports",
                path: "/reports",
                element: <Reports />,
                isNavLink: true
            },
            {
                icon: <FaFileDownload {...icon} />,
                name: "Downloads",
                path: "/downloads",
                element: <Downloads />,
                isNavLink: true
            },
            {
                icon: <ImUser {...icon} />,
                name: "Managers",
                path: "/managers",
                element: <Managers />,
                isNavLink: false
            },
            {
                icon: <FaHotel {...icon} />,
                name: "Rooms",
                path: "/hotels/:hotelId/rooms",
                element: <Rooms />,
                isNavLink: false
            },
            {
                icon: <GrUserWorker  {...icon} />,
                name: "Employees",
                path: "/employees",
                element: <Employees />,
                isNavLink: true
            }
        ]
    },
    {
        title: "Manager",
        layout: "manager",
        role: "MANAGER",
        pages: [
            {
                icon: <IoHome {...icon} />,
                name: "Dashboard",
                path: "/dashboard",
                element: <ManagerDashboard />,
                isNavLink: true
            },
            {
                icon: <FaPersonWalkingLuggage {...icon} />,
                name: "Add Guest",
                path: "/guest",
                element: <Guests />,
                isNavLink: true
            },
            {
                icon: <ImEnter {...icon} />,
                name: "Check In",
                path: "/checkin",
                element: <CheckIn />,
                isNavLink: true
            },
            {
                icon: <ImExit {...icon} />,
                name: "Check Out",
                path: "/checkout",
                element: <Checkout />,
                isNavLink: true
            },
            {
                icon: <FaFileInvoice {...icon} />,
                name: "Cards",
                path: "/cards",
                element: <Cards />,
                isNavLink: true
            },
            {
                icon: <FaFileInvoice {...icon} />,
                name: "Cards",
                path: "/cards/:cardNo",
                element: <CardDetails />,
                isNavLink: false
            },
            {
                icon: <FaBook {...icon} />,
                name: "Invoices",
                path: "/tax-invoices",
                element: <TaxInvoices />,
                isNavLink: true
            },
            {
                icon: <FaIndianRupeeSign {...icon} />,
                name: "Expenses",
                path: "/expenses",
                element: <Expense />,
                isNavLink: true
            },
            {
                icon: <FaIndianRupeeSign {...icon} />,
                name: "Expenses",
                path: "/expenses/add",
                element: <AddExpense />,
                isNavLink: false
            },
            {
                icon: <FaIndianRupeeSign {...icon} />,
                name: "Expenses",
                path: "/expenses/edit",
                element: <EditExpense />,
                isNavLink: false
            },
            {
                icon: <FaMoneyBillWave {...icon} />,
                name: "Cash Tracker",
                path: "/tracker",
                element: <CashTracker />,
                isNavLink: true
            },
            {
                icon: <GiAutoRepair {...icon} />,
                name: "Complaints",
                path: "/complaints",
                element: <Complaints />,
                isNavLink: false
            },
            {
                icon: <GiNotebook {...icon} />,
                name: "Attendance",
                path: "/Attendance",
                element: <Attendance />,
                isNavLink: true
            },
            {
                icon: <GiLoincloth {...icon} />,
                name: "Dhobi",
                path: "/dhobi",
                element: <Dhobi />,
                isNavLink: true
            }
        ]
    }
];

export default routes;