import React, { useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    IconButton,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import { ClockLoader } from 'react-spinners';
import { Link } from 'react-router-dom';


const TABLE_HEAD = ["S.No", "Invoice No", "Date", "Amount", "Guest Details"];

export default function TaxInvoices() {

    const [startDate, setStartDate] = useState(() => getCurrentMonthFirstDay());
    const [endDate, setEndDate] = useState(() => formatDate(1));
    const [guestMobile, setGuestMobile] = useState("");
    const { fetchAccessToken, defaultHotel, isAdmin } = useUserContext();
    const [taxInvoices, setTaxInvoices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { getTaxInvoicesByMobile, getTaxInvoicesByRange } = useApi();

    function getCurrentMonthFirstDay() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    function formatDateFromEpoch(epoch) {
        if (!epoch)
            return "";
        const date = new Date(epoch);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    }

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    const generateReportByDateRange = async () => {
        if (!defaultHotel) {
            return;
        }
        setIsLoading(true);
        try {
            const start = getEpoch(startDate, "00:00");
            const end = getEpoch(endDate, "23:59");
            const token = await fetchAccessToken();
            const data = await getTaxInvoicesByRange(defaultHotel, start, end, token);
            if (data) {
                setTaxInvoices(data);
            } else {
                setTaxInvoices([]);
            }
        } catch (ex) {
            setTaxInvoices([]);
        } finally {
            setIsLoading(false);
        }
    };

    

    const generateReportByMobile = async () => {
        if (!defaultHotel || !guestMobile) {
            return;
        }
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const data = await getTaxInvoicesByMobile(defaultHotel, guestMobile, token);
            if (data) {
                setTaxInvoices(data);
            } else {
                setTaxInvoices([]);
            }
        } catch (ex) {
            setTaxInvoices([]);
        } finally {
            setIsLoading(false);
        }
    };

    function getBasePrice(totalAmount) {
        let basePrice = Math.ceil(totalAmount / 1.12);
        let cgst = Math.ceil((totalAmount / 1.12) * 0.06);
        let sgst = Math.ceil((totalAmount / 1.12) * 0.06);
        return basePrice - (basePrice + cgst + sgst - totalAmount);
    }

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Tax Invoices
                    </Typography>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                {isAdmin && <div className="flex flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Start:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    End:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-4/12 p-1">
                            <div className="h-10 flex justify-end">
                                <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                    type="button" onClick={generateReportByDateRange}>
                                    Get
                                </button>
                            </div>
                        </div>
                    </div>}
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Guest Mobile:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="tel" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={guestMobile} onChange={(event) => setGuestMobile(event.target.value)} />
                                </div>
                                <div className="h-8 flex justify-end ml-4 mt-1">
                                    <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                        type="button" onClick={generateReportByMobile}>
                                        Search
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table className="w-full min-w-max table-auto text-left mt-2">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {taxInvoices.map(({ cardNo, invoiceNo, gstAmount, guestName, guestMobile, createdOn, companyGST, companyDetails }, index) => {
                                const isLast = index === taxInvoices.length - 1;
                                const classes = isLast
                                    ? "p-2"
                                    : "p-2 border-b border-blue-gray-50";
                                return (
                                    <tr key={cardNo}>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {index + 1}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    <Link to={`/manager/cards/${cardNo}`}><u>{invoiceNo}</u></Link>
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div >
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {formatDateFromEpoch(createdOn)}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal mb-2"
                                                >
                                                    Base: {getBasePrice(gstAmount)}.00
                                                </Typography>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal mb-2"
                                                >
                                                    CGST: {Math.ceil((gstAmount / 1.12) * 0.06)}.00
                                                </Typography>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal mb-2"
                                                >
                                                    SGST: {Math.ceil((gstAmount / 1.12) * 0.06)}.00
                                                </Typography>
                                                <hr />
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    Total: {gstAmount}.00
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal opacity-70 mb-2"
                                                >
                                                    {guestName}
                                                </Typography>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal opacity-70 mb-2"
                                                >
                                                    <a href={`tel:${guestMobile}`} style={{ textDecoration: 'underline' }}>{guestMobile}</a>
                                                </Typography>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal opacity-70 mb-2"
                                                >
                                                    {companyGST ?? ""}
                                                </Typography>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal opacity-70"
                                                >
                                                    {companyDetails ?? ""}
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            },
                            )}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    )
}
