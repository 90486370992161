import { Option, Select, Typography } from '@material-tailwind/react';
import React, { useState } from 'react';
import { FaClock } from "react-icons/fa";
import { useLocation, useParams } from 'react-router-dom';
import chartsConfig from "../../configs/charts-config";
import StatisticsChart from '../../widgets/charts/statistics-chart';


const currentMonthBookings = {
    type: "line",
    height: 220,
    options: {
        ...chartsConfig,
        colors: ["#000000", "#388e3c"],
        stroke: {
            curve: "straight",
            dashArray: [0, 0]
        },
        title: {
            text: "Room bookings",
            align: 'left'
        },
        markers: {
            size: 0,
        },
        xaxis: {
            ...chartsConfig.xaxis,
            categories: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"
            ]
        },
    },
    series: [
        {
            name: "Total rooms",
            data: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
        },
        {
            name: "Booked rooms",
            data: [4, 7, 5, 8, 5, 13, 1, 10, 10, 15, 4, 11, 11, 7, 3, 4, 5, 11, 4, 11],
        },
    ],
};

const LastMonthBookings = {
    type: "line",
    height: 220,
    options: {
        ...chartsConfig,
        colors: ["#000000", "#388e3c"],
        stroke: {
            curve: "straight",
            dashArray: [0, 0]
        },
        title: {
            text: "Room bookings",
            align: 'left'
        },
        markers: {
            size: 0,
        },
        xaxis: {
            ...chartsConfig.xaxis,
            categories: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"
            ],
        },
    },
    series: [
        {
            name: "Total rooms",
            data: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15],
        },
        {
            name: "Booked rooms",
            data: [7, 12, 7, 9, 12, 15, 2, 4, 13, 13, 12, 13, 4, 7, 7, 2, 14, 11, 14, 1, 7, 10, 3, 9, 2, 6, 12, 14, 12, 6],
        },
    ],
};

const currentMonthIncome = {
    type: "line",
    height: 220,
    options: {
        ...chartsConfig,
        colors: ["#CD5C5C", "#388e3c"],
        stroke: {
            curve: "straight",
            dashArray: [0, 0]
        },
        title: {
            text: "Income vs Expenditure Overview",
            align: 'left'
        },
        markers: {
            size: 0,
        },
        xaxis: {
            ...chartsConfig.xaxis,
            categories: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20"
            ]
        },
    },
    series: [
        {
            name: "Expenditure",
            data: [300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000],
        },
        {
            name: "Income",
            data: [
                10000, 30000, 50000, 70000, 90000,
                110000, 130000, 150000, 170000, 190000,
                210000, 230000, 250000, 270000, 290000,
                310000, 330000, 350000, 370000, 390000
            ],
        },
    ],
};

const LastMonthIncome = {
    type: "line",
    height: 220,
    options: {
        ...chartsConfig,
        colors: ["#CD5C5C", "#388e3c"],
        stroke: {
            curve: "straight",
            dashArray: [0, 0]
        },
        title: {
            text: "Income vs Expenditure Overview",
            align: 'left'
        },
        markers: {
            size: 0,
        },
        xaxis: {
            ...chartsConfig.xaxis,
            categories: [
                "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30"
            ],
        },
    },
    series: [
        {
            name: "Expenditure",
            data: [300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000, 300000]
            ,
        },
        {
            name: "Income",
            data: [10000, 30000, 50000, 70000, 90000,
                110000, 130000, 150000, 170000, 190000,
                210000, 230000, 250000, 270000, 290000,
                310000, 330000, 350000, 370000, 390000,
                410000, 430000, 450000, 470000, 490000,
                510000, 530000, 550000, 570000, 590000],
        },
    ],
};

export default function HotelStats() {

    const { state } = useLocation();
    const { hotelId } = useParams();
    const { hotelName } = state ?? {};
    const [optionValue, setOptionValue] = useState("20");

    const onOptionChange = (option) => {
        setOptionValue(option)
    };



    return (
        <div className="m-1 flex flex-col gap-12">
            <div className="rounded-lg bg-black p-4">
                <Typography variant="h6" color="white">
                    {hotelName}
                </Typography>
            </div>
            <Select value={optionValue} onChange={(value) => onOptionChange(value)} label="Select" variant="outlined">
                <Option value="20">Current month</Option>
                <Option value="30">Previous month</Option>
            </Select>

            <div className="flex flex-col gap-12">

                {parseInt(optionValue) == 20 && <StatisticsChart key="currentMonthBookings" id="currentMonthBookings" color="white" chart={currentMonthBookings} footer={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                    >
                        <FaClock strokeWidth={2} className="h-4 w-4 text-blue-gray-400" />
                        &nbsp;report updated 2 hours ago
                    </Typography>
                } />}
                {parseInt(optionValue) == 30 && <StatisticsChart key="LastMonthBookings" id="LastMonthBookings" color="white" chart={LastMonthBookings} footer={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                    >
                        <FaClock strokeWidth={2} className="h-4 w-4 text-blue-gray-400" />
                        &nbsp;report updated 2 hours ago
                    </Typography>
                } />}

                {parseInt(optionValue) == 20 && <StatisticsChart key="currentMonthIncome" id="currentMonthIncome" color="white" chart={currentMonthIncome} footer={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                    >
                        <FaClock strokeWidth={2} className="h-4 w-4 text-blue-gray-400" />
                        &nbsp;report updated 1 day ago
                    </Typography>
                } />}
                {parseInt(optionValue) == 30 && <StatisticsChart key="LastMonthIncome" id="LastMonthIncome" color="white" chart={LastMonthIncome} footer={
                    <Typography
                        variant="small"
                        className="flex items-center font-normal text-blue-gray-600"
                    >
                        <FaClock strokeWidth={2} className="h-4 w-4 text-blue-gray-400" />
                        &nbsp;report updated 1 day ago
                    </Typography>
                } />}
            </div>
        </div>
    )
}
