import React, { useState } from 'react';

const SingleSelectChip = ({ options, onChange, borderColor }) => {
    const [selectedOption, setSelectedOption] = useState(options[0]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        onChange(option);
    };

    return (
        <div className="flex flex-wrap overflow-x-auto">
            {options.map((option) => (
                <button
                    key={option.value}
                    onClick={() => handleOptionChange(option)}
                    className={`${selectedOption === option ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
                        } font-small rounded-full px-3 py-1 m-1 focus:outline-none border ${borderColor}`}>
                    {option.label}
                </button>
            ))}
        </div>
    );
};

SingleSelectChip.defaultProps = {
    borderColor: "border-green-400"
};

export default SingleSelectChip;