import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/userContext';
import { FaPersonWalkingLuggage } from "react-icons/fa6";
import { FaHotel } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { GiArchiveRegister } from "react-icons/gi";
import { BsCash } from "react-icons/bs";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { GiLoincloth } from "react-icons/gi";
import { GrTransaction } from "react-icons/gr";
import { FaShieldHalved } from "react-icons/fa6";
import { BsFillFileEarmarkBarGraphFill } from "react-icons/bs";
import { AiFillMessage } from "react-icons/ai";
import { IoLogoWhatsapp } from "react-icons/io";

export default function Home() {
    const navigate = useNavigate();
    const { login, isLoading, isAuthenticated, isAdmin } = useUserContext();

    const [dashboardUrl, setDashboardUrl] = useState("/manager/dashboard");
    useEffect(() => {
        if (isAuthenticated && isAdmin) {
            setDashboardUrl('/admin/dashboard');
        }
    }, [isAuthenticated, isAdmin]);
    return (
        <div className="flex flex-col">
            <header className="text-black py-4 px-6 flex justify-between items-center top-0 left-0 w-full bg-white">
                <div className="flex items-center">
                    <img src="/logo.png" alt="Logo" className="h-12 w-auto rounded-lg" />
                    <h1 className="text-xl font-semibold ml-1 mt-2">InnPro </h1>
                    <h2 className="text-md font-semibold ml-1 mt-3">Software Solutions</h2>
                </div>
                {!isLoading && !isAuthenticated && <button className="text-black-600 hover:text-blue-900 focus:outline-none py-2 px-4" onClick={() => login()}>Login</button>}
                {!isLoading && isAuthenticated && <button className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded" onClick={() => navigate(dashboardUrl)}>Account</button>}
            </header>
            <main className="bg-gray-100 ">
                <div class="container mx-auto p-4">
                    <div class="text-center text-black text-lg md:text-xl leading-relaxed px-4 space-y-4 mb-4">
                        <p class="text-center text-2xl md:text-3xl font-bold text-black">
                            Comprehensive Cloud-Powered Hotel Management Solution
                        </p>
                        <p>Mobile-Friendly Solutions Offering the Below Features to Simplify and Streamline Hotel Operations</p>
                    </div>
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <FaPersonWalkingLuggage className="text-[#f87171] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-8">
                                <h2 class="text-lg font-semibold text-center">Guest Management</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Guest Management streamlines guest handling by managing profiles, preferences, and interactions to enhance personalized service and improve the overall guest experience.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <FaHotel className="text-[#7dd3fc] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Booking Management</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Simplifies the reservation process by allowing efficient room selection, availability tracking, and booking management to ensure a seamless experience for both guests and staff.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <GrUserWorker className="text-[#78350f] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Employee Management</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Employee Management streamlines the administration of staff roles, schedules, and performance tracking to enhance operational efficiency and support effective team collaboration in the hotel.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <GiArchiveRegister className="text-[#c4b5fd] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Attendance</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">The Attendance module enables efficient tracking of employee work hours, schedules, and attendance records, facilitating accurate payroll management and ensuring optimal staffing levels in the hotel.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <BsCash className="text-[#4ade80] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Cash Registry</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">The Cash Registry feature allows for streamlined management of cash transactions, providing real-time tracking of revenues and expenses to ensure accurate financial reporting and enhance accountability in hotel operations.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <FaHandHoldingDollar className="text-[#bef264] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Expense Management</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Expense Management simplifies the tracking and categorization of hotel expenditures, enabling efficient budgeting and financial oversight to optimize operational costs and improve profitability.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <GiLoincloth className="text-[#fdba74] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Dhobi Management</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Dhobi Management streamlines the laundry service operations by efficiently tracking and managing laundry requests, schedules, and inventory, ensuring timely service and maintaining high standards of cleanliness for hotel linens and guest garments.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <GrTransaction className="text-[#6ee7b7] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Transaction Reports</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Transaction Reports provide detailed insights into financial activities by tracking all revenue and expenditure transactions, enabling hotels to analyze performance, identify trends, and make informed financial decisions.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <FaShieldHalved className="text-[#1c1917] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Fraud Prevention</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Fraud Prevention enhances security by sending real-time notifications for all financial transactions and room check-ins or check-outs, ensuring transparency and enabling prompt action against suspicious activities in the hotel.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <BsFillFileEarmarkBarGraphFill className="text-[#fcd34d] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Hotels Insight</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Hotels Insight provides a comprehensive overview of all hotel operations, offering a bird's-eye view of performance metrics, occupancy rates, and other key statistics to facilitate informed decision-making and strategic planning.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <AiFillMessage className="text-[#d4d4d8] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Guest Connect</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Guest Connect fosters engagement by sending feedback messages and promotional codes, enhancing communication with guests and encouraging loyalty through personalized offers and insights.</p>
                            </div>
                        </div>
                        <div class="relative bg-white shadow-lg rounded-2xl flex flex-col items-center py-3 group perspective">
                            <IoLogoWhatsapp className="text-[#86efac] transition-transform duration-500 transform group-hover:rotate-z-180" size={150} />
                            <div class="p-4">
                                <h2 class="text-lg font-semibold text-center">Administrator Notifications</h2>
                            </div>
                            <div class="absolute inset-0 bg-[#F0FDF4] text-white flex items-center justify-center rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                <p class="text-center px-4 text-black">Administrator Notification ensures that hotel administrators receive timely WhatsApp alerts for all significant events within the hotel, enabling prompt responses and efficient management of operations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-black text-white py-8">
                <div className="container mx-auto flex flex-col md:flex-row justify-between items-start px-4 md:px-0">
                    {/* Left Side - About the Company */}
                    <div className="mb-6 md:mb-0 md:w-1/3">
                        <div className="flex flex-row">
                            <img src="/logo.png" alt="Logo" className="h-14 w-auto mr-4 bg-white rounded-lg" />
                            <div>
                                <h2 className="text-xl font-semibold">InnPro</h2>
                                <h2 className="text-md font-semibold">Hotel Management Solution</h2>
                            </div>
                        </div>
                        <p className="text-white-400 mt-2">
                        InnPro is a mobile-friendly hotel management System that simplifies operations and improves guest interactions. Its easy-to-use platform makes hotel management efficient and offers customized experiences on any device.
                        </p>
                    </div>

                    {/* Right Side - Company Address and Mobile Number */}
                    <div className="md:w-1/3">
                        <h2 className="text-md font-semibold mb-4">Contact Us</h2>
                        <p className="text-white-400">
                            902, Ruby, PBEL City
                            <br />
                            Hyderabad, Telangana, 500091
                            <br />
                            <a href="mailto:support@innpro.in" className="text-blue-400 hover:underline">
                                support@innpro.in
                            </a>
                            <br />
                            <a href="tel:+917842396731" className="text-blue-400 hover:underline">
                                +91 78423 96731
                            </a>
                        </p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
