import { Navbar, IconButton, Menu, MenuHandler, MenuList, MenuItem, Typography, Select, Option } from '@material-tailwind/react'
import React from 'react'
import { RiMenuFill } from "react-icons/ri";
import { FaCircleUser } from "react-icons/fa6";
import { useMaterialTailwind, setOpenSidenav, useUserContext } from '../../context';
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

function Topnav() {
  const [controller, dispatch] = useMaterialTailwind();
  const { openSidenav } = controller;

  const navigate = useNavigate();


  const { logout, login, isAuthenticated, user, setDefaultHotel, defaultHotel, assignments } = useUserContext();
  console.log(defaultHotel)
  return (
    <Navbar color='transparent' className="rounded-xl transition-all px-0 py-1 flex justify-between" fullWidth blurred={false}>
      <div className="ml-1">
        <IconButton
          variant="text"
          color="blue-gray"
          className="items-center gap-1 px-4 py-4 xl:flex normal-case"
          onClick={() => navigate(-1)}
        >
          <RiArrowGoBackFill className="h-8 w-8 text-black-500" />
        </IconButton>

      </div>
      <div className="flex flex-col-reverse justify-between gap-6 md:flex-row md:justify-end">
        <div className="flex justify-end">
          <label className="block uppercase text-black ext-xs font-bold mb-2 py-2 pr-5" >
            Hotel:
          </label>
          <div className="mr-auto md:mr-4 md:w-56">
            <select id="defaultHotel" name="defaultHotel" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pr-2 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
              value={defaultHotel}
              onChange={(event) => setDefaultHotel(event.target.value)}>
              <option key={""} value="">Select</option>
              {assignments?.hotels.map((hotel) => (
                <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
              ))}
            </select>
          </div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="grid xl:hidden"
            onClick={() => setOpenSidenav(dispatch, !openSidenav)} >
            <RiMenuFill strokeWidth={3} className="h-6 w-6 text-blue-gray-500" />
          </IconButton>
          <Menu>
            <MenuHandler>
              <IconButton
                variant="text"
                color="blue-gray"
                className="items-center gap-1 px-4 xl:flex normal-case"
              >
                <FaCircleUser className="h-5 w-5 text-blue-gray-500" />
              </IconButton>
            </MenuHandler>
            <MenuList className="w-max border-0">
              <MenuItem className="flex items-center gap-3">
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-normal">
                    {user && user.name}
                  </Typography>
                </div>
              </MenuItem>
              {isAuthenticated && <MenuItem className="flex items-center gap-3"
                onClick={() => isAuthenticated ? logout({ returnTo: window.location.origin }) : login()}
              >
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-normal">
                    {isAuthenticated ? "Logout" : "Login"}
                  </Typography>
                </div>
              </MenuItem>}

            </MenuList>
          </Menu>
        </div>
      </div>
    </Navbar>
  )
}

export default React.memo(Topnav);
