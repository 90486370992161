import React from 'react';
import { Tooltip, Typography } from '@material-tailwind/react';

const getTotalAmount = (payments, mode) => {
    return payments.reduce((total, payment) => {
        return payment.paymentMode === mode ? total + payment.paymentAmount : total;
    }, 0);
};

export default function AmountCard({ payments }) {
    const modes = [...new Set(payments.map(payment => payment.paymentMode))];

    return (
        <Tooltip
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
            }}
            content={
                <table className="min-w-full divide-y text-blue-gray-500">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mode</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                            <th className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Date</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y ">
                        {payments.map((payment, index) => (
                            <tr key={index}>
                                <td className="px-3 py-3 whitespace-nowrap">{payment.paymentMode}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{payment.paymentAmount}</td>
                                <td className="px-3 py-3 whitespace-nowrap">{new Date(payment.paymentDateTime).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            }
        >
            <Typography
                variant="small"
                color="blue-gray"
                className="font-normal cursor-pointer"
            >
                <table className="table-auto">
                    <tbody>
                        {modes.map(mode => (
                            <tr key={mode}>
                                <td>{mode} </td>
                                <td className="w-5"></td>
                                <td>{getTotalAmount(payments, mode)}</td>
                            </tr>
                        ))}
                        <tr>
                            <td>Total</td>
                            <td></td>
                            <td className="underline">
                                {payments.reduce((total, payment) => total + payment.paymentAmount, 0)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Typography>
        </Tooltip>
    )
}
