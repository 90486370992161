import {
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader
} from "@material-tailwind/react";
import React, { useState } from 'react';

function CollectPayment({ cardNo, isOpen, onClose, onSubmit }) {

    const [paymentMode, setPaymentMode] = useState("");
    const [amount, setAmount] = useState(0);
    const [attachment, setAttachment] = useState(null);

    let handleSubmitInternal = async () => {
        if (cardNo && paymentMode && amount > 0) {
            await onSubmit(cardNo, paymentMode, amount, attachment);
        }
    };

    let onCloseInternal = () => {
        onClose();
    };

    const handleFileChange = (e) => {
        let file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            compressImage(file, (compressedBlob) => {
                setAttachment(compressedBlob);

            });
        } else {
            alert('Please upload a JPEG or PNG image.');
        }
    };

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const fileType = file.type;
                const isJPEG = fileType === 'image/jpeg' || fileType === 'image/jpg';

                let quality = isJPEG ? 1.0 : undefined;
                const maxFileSize = 1000000; // 1MB

                const compress = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size <= maxFileSize || (isJPEG && quality < 0.1)) {
                            callback(blob);
                        } else if (isJPEG) {
                            quality -= 0.1;
                            compress();
                        } else {
                            // For PNG, if the first compression is too large, we can't reduce quality, so we just use the first result
                            callback(blob);
                        }
                    }, fileType, quality);
                };

                compress();
            };
        };
        reader.readAsDataURL(file);
    };



    return (
        <Dialog open={isOpen}>
            <DialogHeader>Collect Advance</DialogHeader>
            <DialogBody>
                <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="paymentMode" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Payment Type <span className="text-red-500">*</span></label>
                        <select label="Payment Type" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                            name="paymentMode" id="paymentMode"
                            onChange={(event) => setPaymentMode(event.target.value)}
                            value={paymentMode}>
                            <option key="" value="">Select</option>
                            <option key="Cash" value="Cash">Cash</option>
                            <option key="UPI" value="UPI">UPI</option>
                            <option key="Card" value="Card">Card</option>
                        </select>
                    </div>
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="amount" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount<span className="text-red-500">*</span></label>
                        <input type="number" id="amount" name="amount"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={amount} onChange={(event) => setAmount(parseInt(event.target.value, 10))} />
                    </div>
                    <div className="flex flex-col mr-3 mt-3 mb-3">
                        <label htmlFor="proof" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Proof</label>
                        <div className="flex flex-col lg:w-4/12">
                            <div className="py-3">
                                <input type="file" accept="image/*" onChange={handleFileChange} />
                            </div>
                        </div>
                        <div className="relative w-full px-5">
                            {attachment &&
                                <div>
                                    <img src={URL.createObjectURL(attachment)} style={{ height: "100px", width: "100px", margin: "5px" }} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={onCloseInternal}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                    <span>Save</span>
                </Button>
            </DialogFooter>
        </Dialog>
    );
}

export default CollectPayment;