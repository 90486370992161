import React from "react";
import ReactDOM from 'react-dom/client'
import { ThemeProvider } from "@material-tailwind/react";
import { HashRouter } from "react-router-dom";
import App from "./App";
import { MaterialTailwindProvider } from "./context";
import "./index.css";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { UserContextProvider } from "./context/userContext";

//https://developer.auth0.com/resources/guides/spa/react/basic-authentication
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <ThemeProvider>
      <HashRouter>
        <MaterialTailwindProvider>
          <Auth0ProviderWithNavigate>
            <UserContextProvider>
              <App />
            </UserContextProvider>
          </Auth0ProviderWithNavigate>
        </MaterialTailwindProvider>
      </HashRouter>
    </ThemeProvider>
  </>
);
