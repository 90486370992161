import {
    Dialog,
    DialogBody,
    IconButton, Menu, MenuHandler, MenuItem, MenuList, Typography
} from '@material-tailwind/react';
import React, { useState } from 'react';
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import CollectPayment from '../../pages/manager/collect-advance';
import { ClockLoader } from 'react-spinners';
import CheckoutSingle from '../../pages/manager/checkout-single';

const cardBgColors = {
    "checked-in": "from-blue-200",
    "vacant": "",
    "due": "from-red-800",
};

const cardOptions = {
    "checked-in": ["Check Out Card", "Collect Advance", "Shift Room", "Convert Room", "Check Out Single"],
    "vacant": ["Check-In"],
    "due": ["Collect Advance", "Check Out Card", "Extend"]
};

export default function RoomCard({ status, roomDetails, onRoomShiftRequest, onRoomExtend }) {

    const navigate = useNavigate();
    const [isOpenAdvancePopup, setIsOpenAdvancePopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { collectAdvance, uploadDocument, singleCheckout } = useApi();
    const { fetchAccessToken, defaultHotel } = useUserContext();
    const [showCheckoutSinglePopup, setShowCheckoutSinglePopup] = useState(false);
    let onClosePopup = () => {
        setIsOpenAdvancePopup(false);
    };

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    function getDateAndTime(date, time) {
        const [hours, mins] = time.split(":");
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(mins);

        const formattedDateTime = `${combinedDate.getDate().toString().padStart(2, '0')}-${(combinedDate.getMonth() + 1).toString().padStart(2, '0')}-${combinedDate.getFullYear()} ${combinedDate.getHours().toString().padStart(2, '0')}:${combinedDate.getMinutes().toString().padStart(2, '0')}`;
        return formattedDateTime;
    }

    let onCheckoutSingleInternal = async ({ cardNo, roomNo, date, time }) => {
        setShowCheckoutSinglePopup(false);
        const checkoutDateTime = getDateAndTime(date, time);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            const roomDetail = {
                roomNo: roomNo,
                checkOutDateTime: checkoutDateTime,
            };
            await singleCheckout(defaultHotel, cardNo, roomDetail, token)
            alert("Checkout successful.");
            window.location.reload();
        } catch (ex) {
            alert("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }

    let onCollectAdvanceInternal = async (cardNo, paymentMode, amount, attachment) => {
        setIsOpenAdvancePopup(false);
        setIsLoading(true);
        let documentS3Id = "";
        const token = await fetchAccessToken();
        if (attachment) {
            let base64String = await convertBlobToBase64(attachment);
            documentS3Id = await uploadDocument(base64String, token);
        }
        try {
            const paymentDetail = {
                paymentMode: paymentMode,
                paymentAmount: amount,
                paymentDateTime: Date.now(),
                isNew: true
            };
            await collectAdvance(defaultHotel, roomDetails.cardNo, paymentDetail, documentS3Id, token);
            alert("Advance collected. Please refresh the page.");
        } catch (ex) {
            alert("Something went wrong");
        }
        finally {
            setIsLoading(false);
        }
    }

    const onMenuClick = (opt) => {
        switch (opt) {
            case "Check-In":
                navigate("/manager/checkin", { state: { roomNo: roomDetails.roomNo } });
                return;
            case "Collect Advance":
                setIsOpenAdvancePopup(true);
                return;
            case "Shift Room":
                if (roomDetails.cardNo) {
                    onRoomShiftRequest(roomDetails.cardNo, roomDetails.roomNo);
                }
                return;
            case "Check Out Card":
                navigate("/manager/checkout", { state: { cardNo: roomDetails.cardNo, guestId: roomDetails.guestMobile, guestName: roomDetails.guestName } });
                return;

            case "Check Out Single":
                setShowCheckoutSinglePopup(true);
                return;
            case "Extend":
                if (roomDetails.cardNo && roomDetails.roomNo) {
                    onRoomExtend(roomDetails.cardNo, roomDetails.roomNo);
                }
                return;
            case "Add Reference":
                alert("TODO");
                return;
            default:
                alert("TODO");
                return;
        }
    };

    function getCardColor(roomDetails) {
        if (status === "checked-in") {
            if (roomDetails.bookingType === " - Online") {
                return "from-green-200";
            }
            if (roomDetails.bookingType === " - Free") {
                return "from-yellow-200";
            }
            if (roomDetails.bookingType === " - Couple") {
                return "from-pink-200";
            }
        }
        return cardBgColors[status];
    }


    return (
        <div className="overflow-hidden m-4 rounded-xl bg-white border border-gray-400 dark:border-gray-800 dark:bg-gray-900 text-gray-900" >
            <div className={`bg-gradient-to-b ${getCardColor(roomDetails)} to-white dark:from-white dark:to-white p-2 font-small `}>
                <div className="flex justify-between">
                    <Typography variant="h4" className="ml-5 mt-1">
                        {roomDetails.roomNo}
                    </Typography>
                    <Typography variant="h6" className="ml-1 mt-2">
                        {roomDetails.category}
                    </Typography>
                    <Menu>
                        <MenuHandler>
                            <IconButton variant="text">
                                <BiDotsVerticalRounded className="h-6 w-6" />
                            </IconButton>
                        </MenuHandler>
                        <MenuList className="flex flex-col gap-2">
                            {cardOptions[status].map((option, i) =>
                                <MenuItem key={i} className="flex items-center gap-4 py-2 pl-2 pr-8" onClick={() => onMenuClick(option)}>
                                    <div className="flex flex-col gap-1">
                                        <Typography variant="small" color="gray" className="font-semibold">
                                            {option}
                                        </Typography>
                                    </div>
                                </MenuItem>
                            )}
                            {roomDetails.bookingType === " - Online" &&
                                <MenuItem key={999} className="flex items-center gap-4 py-2 pl-2 pr-8" onClick={() => onMenuClick("Add Reference")}>
                                    <div className="flex flex-col gap-1">
                                        <Typography variant="small" color="gray" className="font-semibold">
                                            Add Reference
                                        </Typography>
                                    </div>
                                </MenuItem>
                            }
                        </MenuList>
                    </Menu>
                </div>
                <hr className="my-1 border-gray-200" />
                <div className="p-2 overflow-x-auto">
                    <table className="table-auto min-w-full divide-y divide-gray-200 rounded-lg overflow-hidden">
                        <tbody className="divide-y divide-gray-200">
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Card No:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700 overflow-hidden">
                                    {roomDetails.cardNo} {roomDetails.bookingType}
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Customer Details:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700 overflow-hidden">
                                    {roomDetails.guestName} {roomDetails.reference}<br />
                                    <a href={`tel:${roomDetails.guestMobile}`} style={{ textDecoration: 'underline' }}>{roomDetails.guestMobile}</a>
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Checked-In:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700">
                                    {roomDetails.checkedInTime}
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Checkout Date:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700">
                                    {roomDetails.checkedOutTime}
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Days:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700">
                                    {roomDetails.days}
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Amount Received:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700">
                                    {roomDetails.amountReceived}
                                </td>
                            </tr>
                            <tr className="bg-white">
                                <td className="px-2 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
                                    Amount Due:
                                </td>
                                <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-700">
                                    {roomDetails.amountDue}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {isOpenAdvancePopup && <CollectPayment cardNo={roomDetails.cardNo} isOpen={isOpenAdvancePopup} onClose={onClosePopup} onSubmit={onCollectAdvanceInternal} />}
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            {showCheckoutSinglePopup &&
                <CheckoutSingle cardNo={roomDetails.cardNo} roomNo={roomDetails.roomNo} isOpen={showCheckoutSinglePopup} onClose={() => setShowCheckoutSinglePopup(false)} onSubmit={onCheckoutSingleInternal} />
            }
        </div>
    )
}
