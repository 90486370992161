import { useAuth0 } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useApi } from "../hooks/useApi";
import { ClockLoader } from 'react-spinners';
import { jwtDecode } from "jwt-decode";

export const UserContext = createContext();

export const useUserContext = () => {
    return useContext(UserContext);
}

export function UserContextProvider(props) {
    const [isAdmin, setIsAdmin] = useState(false);
    const [assignments, setAssignments] = useState({ roles: ["MANAGER"], hotels: [] });
    const { getHotels } = useApi();
    const [defaultHotel, setDefaultHotel] = useState("");
    const {
        isAuthenticated,
        isLoading,
        user,
        error,
        getIdTokenClaims,
        getAccessTokenSilently,
        loginWithRedirect: login,
        logout } = useAuth0();


    useEffect(() => {
        if (isAuthenticated && user) {
            const userRoles = user.app_metadata.roles;
            setIsAdmin(userRoles.includes("ADMIN"));
            setAssignments(prevState => ({
                ...prevState,
                roles: userRoles
            }));
        }
    }, [isAuthenticated, user]);


    useEffect(() => {
        const handleUserInfo = async () => {
            if (!isLoading && user && isAuthenticated) {
                try {
                    const token = await fetchAccessToken();
                    const hotels = await getHotels(token);
                    setAssignments(prevState => ({
                        ...prevState,
                        hotels: hotels
                    }));
                    if (hotels.length > 0) {
                        const defaultHotelLocal = localStorage.getItem('defaultHotel');
                        if (defaultHotelLocal && hotels.find(hotel => hotel.id === defaultHotelLocal)) {
                            setDefaultHotel(defaultHotelLocal);
                        } else {
                            setDefaultHotel(hotels[0].id);
                        }
                    }
                } catch (ex) {
                }
            }
        };

        handleUserInfo();
    }, [isLoading, user, isAuthenticated]);

    useEffect(() => {
        if (defaultHotel) {
            localStorage.setItem('defaultHotel', defaultHotel);
        }
    }, [defaultHotel]);



    const fetchAccessToken = async () => {
        let tokenClaims = await getIdTokenClaims();
        let { __raw: idToken } = tokenClaims;

        if (isTokenExpired(idToken)) {
            try {
                // Try to silently get a new access token
                await getAccessTokenSilently({
                    authorizationParams: {
                        scope: "openid profile email"
                    },
                    detailedResponse: false,
                    cacheMode: "off"
                });
                // After getting a new token, refresh the ID token claims
                tokenClaims = await getIdTokenClaims();
                idToken = tokenClaims.__raw;
                // If the new token is still expired, fallback to an interactive login
                if (isTokenExpired(idToken)) {
                    await login();
                }
            } catch (ex) {
                await login();
            }
        }
        return idToken;
    };

    function isTokenExpired(idToken) {
        const decodedToken = jwtDecode(idToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const bufferTime = 60;
        return decodedToken.exp < currentTimestamp + bufferTime;
    }


    return <UserContext.Provider value={{ logout, login, fetchAccessToken, setDefaultHotel, defaultHotel, isLoading, isAuthenticated, user, isAdmin, assignments, error }}>
        {isLoading && <ClockLoader color="#2be60d" />}
        {!isLoading && props.children}
    </UserContext.Provider>
}