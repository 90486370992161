import React, { useState } from 'react';
import PropTypes from "prop-types";

export default function TimePicker({ defaultTime, onTimeChange, isDisabled = false }) {
    const [time, setTime] = useState(defaultTime);
    const handleInputChange = (event) => {
        setTime(event.target.value);
        onTimeChange(event.target.value);
    }

    React.useEffect(() => {
        if (defaultTime !== time) {
            setTime(defaultTime);
        }
    }, [defaultTime]);

    return (
        <div className="relative ml-2 mr-2">
            <input type="time" id="time" className={`${isDisabled ? 'bg-gray-200' : 'bg-white'} text-gray-900 border leading-none border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-9.5`}
                min="00:00" max="23:59" value={time}
                onChange={handleInputChange}
                disabled={isDisabled} />
        </div>
    )
}

TimePicker.defaultProps = {
    defaultTime: "",
    onTimeChange: d => { }
};

TimePicker.propTypes = {
    defaultTime: PropTypes.string.isRequired,
    onTimeChange: PropTypes.func.isRequired,
};
