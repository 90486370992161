import { Dialog, DialogBody, Typography } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import HotelStatCard from '../../widgets/cards/hotel-stat-card';
import { subMonths, startOfMonth, endOfMonth, getTime, format } from 'date-fns';

export default function Dashboard() {

    const { fetchAccessToken, defaultHotel, assignments } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const { getCheckedInRooms, getProfitDetails } = useApi();
    const [hotelsStatData, setHotelStatData] = useState([]);
    const [profitDetails, setProfitDetails] = useState([]);
    const [selectMonth, setSelectMonth] = useState("0,0")

    useEffect(() => {
        let getHotelStats = async (hotelIds) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const responses = await Promise.all(hotelIds.map(async hotelId => {
                    const response = await getCheckedInRooms(hotelId, token);
                    return { hotelId, response };
                }));
                const formatted = assignments?.hotels?.map(hotel => {
                    const hotelStat = responses?.find(h => h.hotelId === hotel.id);
                    return {
                        hotelId: hotel.id,
                        hotelName: hotel.name,
                        totalRooms: hotel.rooms?.length,
                        checkedInRooms: hotelStat?.response?.length || 0
                    }
                });
                setHotelStatData(formatted);
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        };

        if (defaultHotel) {
            const hotelIds = assignments?.hotels?.map(hotel => hotel.id);
            getHotelStats(hotelIds);
        }
    }, [defaultHotel]);

    const getLastSixMonthsOptions = () => {
        const options = [];
        for (let i = 0; i <= 6; i++) {
            const currentMonth = subMonths(new Date(), i);
            let start = startOfMonth(currentMonth);
            let end = endOfMonth(currentMonth);

            let label = format(start, 'MMM yyyy');
            if (i === 0) {
                label = "Current Month";
            }

            const value = `${getTime(start)},${getTime(end)}`;

            options.push({ label, value });
        }

        return options;
    };

    const monthOptions = getLastSixMonthsOptions();

    const getProfitDetailsInternal = async () => {
        const [startStr, endStr] = selectMonth.split(',');
        const start = Number(startStr);
        const end = Number(endStr);
        if (start && end) {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const data = await getProfitDetails(start, end, token);
                if (data && data.length > 0) {
                    setProfitDetails(data);
                }
            } catch (ex) {
                alert("Something went wrong.");
            } finally {
                setIsLoading(false);
            }
        } else {
            setProfitDetails([]);
        }
    };

    function getHotelName(hotelId) {
        const hotel = assignments?.hotels?.find(h => h.id === hotelId);
        if (hotel) {
            return hotel.name;
        }
        return hotelId;
    }

    function getTotalIncome() {
        return profitDetails.reduce((sum, item) => sum + item.income, 0)
    }

    function getTotalExpense() {
        return profitDetails.reduce((sum, item) => sum + item.expense, 0)
    }

    function getPendingAmount() {
        return profitDetails.reduce((sum, item) => sum + item.pendingAmount, 0)
    }

    return (
        <div className="mt-12">
            <div className="mb-10 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-3">
                {
                    hotelsStatData.map((hotelStatData, i) => <HotelStatCard key={i} {...hotelStatData} />)
                }
            </div>
            <div className="flex flex-wrap px-3">
                <label className="text-blueGray-600 text-lg font-bold mb-2 flex flex-row" >
                    Profit Analysis
                </label>
            </div>
            <div className="flex flex-wrap">
                <div className="flex flex-row px-6">
                    <Typography className="mt-3">
                        Month:
                    </Typography>
                    <select label="reportMonth" color="blue" className="ml-4 mb-1 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                        name="reportMonth" id="reportMonth" onChange={(event) => setSelectMonth(event.target.value)}
                        value={selectMonth}>
                        <option key="0,0" value="0,0">Select</option>
                        {monthOptions.map(monthOption => <option key={monthOption.value} value={monthOption.value}>{monthOption.label}</option>)}
                    </select>
                </div>
                <div className="flex px-4 h-10">
                    <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                        type="button" onClick={getProfitDetailsInternal}>
                        Generate
                    </button>
                </div>
            </div>
            <div className="px-4 flex flex-wrap overflow-scroll">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Hotel Name</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Income</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Expenditure</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Profit</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Receivables</th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {profitDetails.length === 0 && <tr>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-red-300 text-center" colSpan={4}> Select month and generate profit analysis </td>
                        </tr>}
                        {profitDetails.sort((a, b) => b.income - a.income).map((profit, index) => (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{getHotelName(profit.hotelId)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{profit.income.toFixed(2)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{profit.expense.toFixed(2)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{(profit.income - profit.expense).toFixed(2)}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">{profit.pendingAmount.toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">Total</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">{getTotalIncome()}.00</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">{getTotalExpense()}.00</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">{getTotalIncome() - getTotalExpense()}.00</th>
                            <th className="px-6 py-3 text-left text-sm font-medium text-gray-900 uppercase tracking-wider">{getPendingAmount()}.00</th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    )
}
