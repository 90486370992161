import { PencilIcon } from "@heroicons/react/24/solid";
import {
    Card,
    CardBody,
    CardHeader,
    IconButton,
    Tooltip,
    Typography
} from "@material-tailwind/react";
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../context';

const TABLE_HEAD = ["Hotel Name", "Rooms", "Address", "Phone number(s)", "GST", "Actions"];



export default function Hotels() {

    const navigate = useNavigate();
    const { assignments } = useUserContext();
    return (
        <Card className="h-full w-full">
            <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6">
                <Typography variant="h6" color="white">
                    Hotels
                </Typography>
            </CardHeader>
            <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                <table className="mt-0 w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                    key={head}
                                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {assignments?.hotels.map(
                            ({ id, rooms, name, address, mobile, gst }, index) => {
                                const isLast = index === assignments?.hotels.length - 1;
                                const classes = isLast
                                    ? "p-4"
                                    : "p-4 border-b border-blue-gray-50";

                                return (
                                    <tr key={id}>
                                        <td className={classes}>
                                            <div className="flex items-center gap-3">
                                                <div className="flex flex-col">
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        <Link to={`/manager/dashboard`} state={{ hotelId: id }}><u>{name}</u></Link>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {rooms.length}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes} width={200}>
                                            <div className="flex flex-col">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal whitespace-pre-line"
                                                >
                                                    {address}
                                                </Typography>
                                            </div>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {mobile}
                                            </Typography>
                                        </td>
                                        <td className={classes}>
                                            <Typography
                                                variant="small"
                                                color="blue-gray"
                                                className="font-normal"
                                            >
                                                {gst}
                                            </Typography>
                                        </td>
                                        <td className={classes}>

                                            <Tooltip content="Edit Details">
                                                <IconButton variant="text" onClick={() => navigate(`/admin/hotels/${id}`)}>
                                                    <PencilIcon className="h-4 w-4" />
                                                </IconButton>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                );
                            },
                        )}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    )
}
