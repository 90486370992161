import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import {
    Card,
    CardBody,
    CardHeader,
    Dialog,
    DialogBody,
    Typography
} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';

const TABLE_HEAD = ["S.No", "Type", "Date", "Amount", "Remarks"];

export default function CashTracker() {
    const [startDate, setStartDate] = useState(() => getCurrentMonthFirstDay());
    const [startTime, setStartTime] = useState("00:00");
    const [endDate, setEndDate] = useState(() => formatDate(0));
    const [endTime, setEndTime] = useState(() => getCurrentTime(0));
    const { defaultHotel, fetchAccessToken, user } = useUserContext();
    const { getPayments, getExpenses } = useApi();
    const [payments, setPayments] = useState([]);
    const [expenses, setExpenses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function getCurrentMonthFirstDay() {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}-01`;
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    useEffect(() => {
        let getPaymentsInternal = async (hotelId) => {
            setIsLoading(true);
            try {
                const start = getEpoch(startDate, startTime);
                const end = getEpoch(endDate, endTime);
                const token = await fetchAccessToken();
                const paymentsData = await getPayments(hotelId, start, end, token);
                if (paymentsData && paymentsData.length > 0) {
                    const cashPayments = paymentsData.filter(item => item.paymentMode === "Cash");
                    setPayments(cashPayments);
                }
                const expensesData = await getExpenses(defaultHotel, start, end, token);
                if (expensesData && expensesData.length > 0) {
                    const cashExpenses = expensesData.filter(expense => expense.cash);
                    setExpenses(cashExpenses);
                } else {
                    setExpenses([]);
                }
            } catch (ex) {
                alert("Something went wrong");
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getPaymentsInternal(defaultHotel);
        }

    }, [defaultHotel]);

    const handleGenerateReport = async () => {
        if (!defaultHotel) {
            return;
        }
        setIsLoading(true);
        try {
            const start = getEpoch(startDate, startTime);
            const end = getEpoch(endDate, endTime);
            const token = await fetchAccessToken();
            const paymentsData = await getPayments(defaultHotel, start, end, token);
            if (paymentsData && paymentsData.length > 0) {
                const cashPayments = paymentsData.filter(item => item.paymentMode === "Cash");
                setPayments(cashPayments);
            }
            const expensesData = await getExpenses(defaultHotel, start, end, token);
            if (expensesData && expensesData.length > 0) {
                const cashExpenses = expensesData.filter(expense => expense.cash);
                setExpenses(cashExpenses);
            }
        } catch (ex) {
            alert("Something went wrong");
        } finally {
            setIsLoading(false);
        }
    }

    function getTotalCashAmount() {
        return payments.reduce((total, obj) => total + obj.paymentAmount, 0);
    }

    function getTotalExpenses() {
        return expenses.reduce((total, expense) => total + expense.expenseAmount, 0);
    }

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Cash Tracker
                    </Typography>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Start:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                                    <input type="time" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={startTime} onChange={(event) => setStartTime(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-4/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    End:
                                </Typography>
                                <div className="flex flex-row">
                                    <input type="date" className="border-0  ml-2 mr-2  px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                                    <input type="time" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        value={endTime} onChange={(event) => setEndTime(event.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="w-full sm:w-4/12 p-1">
                            <div className="h-10 flex justify-end">
                                <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150"
                                    type="button" onClick={handleGenerateReport}>
                                    Run
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <thead>
                            <tr>
                                {TABLE_HEAD.map((head) => (
                                    <th
                                        key={head}
                                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                                    >
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal leading-none opacity-70"
                                        >
                                            {head}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {expenses.map(
                                ({ expenseAmount, expenseDate, expenseType, remarks }, index) => {
                                    const classes = "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {index + 1}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Expense
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {expenseDate}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {expenseAmount}.00
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {expenseType} - {remarks}
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                            {payments.map(
                                ({ paymentAmount, paymentDateTime, cardNo }, index) => {
                                    const isLast = index === expenses.length - 1;
                                    const classes = isLast
                                        ? "p-2"
                                        : "p-2 border-b border-blue-gray-50";
                                    return (
                                        <tr key={index}>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {expenses.length + index + 1}
                                                </Typography>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Cash Received
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="font-normal"
                                                >
                                                    {new Date(paymentDateTime).toLocaleString()}
                                                </Typography>
                                            </td>

                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {paymentAmount}.00
                                                    </Typography>
                                                </div>
                                            </td>
                                            <td className={classes}>
                                                <div>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        Card# {cardNo}
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                    <table className="mt-0 w-full min-w-max table-auto text-left">
                        <tr className="bg-gray-50 border-t-2 border-gray-500">
                            <td className="px-6 py-4 whitespace-nowrap text-right">Cash Received</td>
                            <td className="px-6 py-4 whitespace-nowrap">{getTotalCashAmount()}.00</td>
                            <td></td>
                        </tr>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-right">Expenses</td>
                            <td className="px-6 py-4 whitespace-nowrap">{getTotalExpenses()}.00</td>
                            <td></td>
                        </tr>
                        <tr className="bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap text-right">Cash in hand</td>
                            <td className="px-6 py-4 whitespace-nowrap">{getTotalCashAmount() - getTotalExpenses()}.00</td>
                            <td></td>
                        </tr>
                    </table>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div>
    );
}