import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Dialog, DialogBody,
    DialogFooter,
    DialogHeader,
    Typography,
} from "@material-tailwind/react";
import { ClockLoader } from 'react-spinners';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import Calendar from '../../components/calender';

const TABLE_HEAD = ["S.No", "Date", "Employee", "Hotel", "Shifts", "Reamrks", "Actions"];

export default function Attendance() {

    const [isLoading, setIsLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [attendanceDayLogs, setAttendanceDayLogs] = useState([]);
    const [attendanceNightLogs, setAttendanceNightLogs] = useState([]);
    const [attendanceDate, setAttendanceDate] = useState(() => formatDate(0));
    const [shift, setShift] = useState("");
    const [remark, setRemark] = useState("");
    const { fetchAccessToken, defaultHotel, assignments, isAdmin } = useUserContext();
    const { getEmployees, logAttendance, getAttendances, deleteAttendance } = useApi();
    const [attendanceLocation, setAttendanceLocation] = useState("");
    const [showLogAttendancePopup, setShowLogAttendancePopup] = useState(false);

    const [selectedMonth, setSelectedMonth] = useState(new Date());

    function getEpoch(date, time, seconds = 0) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function formatDate(daysAdd = 0) {
        const date = new Date();
        date.setDate(date.getDate() + daysAdd);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };


    useEffect(() => {
        let getEmployeesInternal = async (hotelId) => {
            setIsLoading(true);
            setSelectedEmployee("");
            setAttendanceDayLogs([]);
            setAttendanceNightLogs([]);
            try {
                const token = await fetchAccessToken();
                const data = await getEmployees(hotelId, token);
                if (data && data.length) {
                    setEmployees(data);
                } else {
                    setEmployees([]);
                }
            } catch (ex) {
                setEmployees([]);
            } finally {
                setIsLoading(false);
            }
        }
        if (defaultHotel) {
            getEmployeesInternal(defaultHotel);
        } else {
            getEmployeesInternal([]);
        }

    }, [defaultHotel]);

    useEffect(() => {
        const getAttendancesInternal = async (employeeId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                const start = getEpoch(getStartOfMonth(selectedMonth), "00:00");
                const end = getEpoch(getEndOfMonth(selectedMonth), "23:59");
                const data = await getAttendances(defaultHotel, employeeId, start, end, token);
                if (data && data.length) {
                    setAttendanceDayLogs(data.filter(d => d.shift === "DAY"));
                    setAttendanceNightLogs(data.filter(d => d.shift === "NIGHT"));
                } else {
                    setAttendanceDayLogs([]);
                    setAttendanceNightLogs([]);
                }
            } catch (ex) {
                setAttendanceDayLogs([]);
                setAttendanceNightLogs([]);
            } finally {
                setIsLoading(false);
            }
        };
        if (selectedEmployee) {
            getAttendancesInternal(selectedEmployee);
        }
    }, [selectedEmployee, selectedMonth]);

    const handleMonthChange = (newMonth) => {
        setSelectedMonth(newMonth);
    };

    function showLogAttendanceInternal(date, _shift, existingDetails) {
        if (!selectedEmployee) {
            alert("Please select the employee");
            return;
        }
        if (existingDetails) {
            let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === existingDetails.hotelId.toLowerCase());
            if (!hotel) {
                alert("You cannot log or view this entry. Please contact hotel Administrator.");
                return;
            }
            setAttendanceDate(existingDetails.date);
            setShift(existingDetails.shift);
            setAttendanceLocation(existingDetails.hotelId);
        } else {
            setAttendanceDate(date);
            setShift(_shift);
            if (assignments && assignments.hotels.length === 1) {
                setAttendanceLocation(assignments.hotels[0].id);
            } else {
                setAttendanceLocation("");
            }
        }
        setShowLogAttendancePopup(true);

    }

    const getAttendancesExternal = async () => {
        if (!selectedEmployee) {
            return;
        }
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let employeeId = selectedEmployee;
            const start = getEpoch(getStartOfMonth(selectedMonth), "00:00");
            const end = getEpoch(getEndOfMonth(selectedMonth), "23:59");
            const data = await getAttendances(defaultHotel, employeeId, start, end, token);
            if (data && data.length) {
                setAttendanceDayLogs(data.filter(d => d.shift === "DAY"));
                setAttendanceNightLogs(data.filter(d => d.shift === "NIGHT"));
            } else {
                setAttendanceDayLogs([]);
                setAttendanceNightLogs([]);
            }
        } catch (ex) {
            setAttendanceDayLogs([]);
            setAttendanceNightLogs([]);
        } finally {
            setIsLoading(false);
        }
    };

    function getStartOfMonth(date) {
        const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        return startDate;
    }

    function getEndOfMonth(date) {
        const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return endDate;
    }

    const logAttendanceInternal = async () => {
        if (!selectedEmployee || !attendanceLocation) {
            return;
        }
        setShowLogAttendancePopup(false);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let shiftTime = "08:00";
            if (shift === "NIGHT") {
                shiftTime = "20:00";
            }
            const payload = {
                hotelId: attendanceLocation,
                employeeId: selectedEmployee,
                employeeName: "",
                date: attendanceDate,
                dateEpoch: getEpoch(attendanceDate, shiftTime),
                shift: shift,
                remarks: remark
            }
            payload.employeeName = employees.find(emp => emp.id === selectedEmployee)?.name || "";
            await logAttendance(defaultHotel, payload, token);
            await getAttendancesExternal();
            setRemark("");
            setAttendanceLocation("");
        } catch (ex) {
            alert("Somethig went wrong.")
        } finally {
            setIsLoading(false);
        }
    };

    const deleteAttendanceInternal = async () => {
        if (!selectedEmployee || !attendanceLocation) {
            return;
        }
        const result = window.confirm("Do you want to delete this entry?");
        if (!result) {
            return;
        }
        setShowLogAttendancePopup(false);
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let shiftTime = "08:00";
            if (shift === "NIGHT") {
                shiftTime = "20:00";
            }
            const logdate = getEpoch(attendanceDate, shiftTime);
            await deleteAttendance(defaultHotel, selectedEmployee, logdate, token);
            await getAttendancesExternal();
            setRemark("");
            setAttendanceLocation("");
        } catch (ex) {
            alert("Somethig went wrong.")
        } finally {
            setIsLoading(false);
        }
    };

    const getTotal = (days, nights) => {
        let dayCount = days.reduce((total, item) => {
            return total + (item.shift ? 1 : 0);
        }, 0);
        let nightCount = nights.reduce((total, item) => {
            return total + (item.shift ? 1 : 0);
        }, 0);
        return dayCount + nightCount;
    };

    function getEmployeeName(employeeId) {
        return employees.find(emp => emp.id === employeeId)?.name || "";
    }

    function getHotelName(hotelid) {
        let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === hotelid.toLowerCase());
        if (hotel) {
            return hotel.name;
        }
        return "Other Hotel";
    }

    const updatedDayLogs = attendanceDayLogs.map(d => {
        return { ...d, hotelName: getHotelName(d.hotelId) };
    });

    const updatedNightLogs = attendanceNightLogs.map(d => {
        return { ...d, hotelName: getHotelName(d.hotelId) };
    });

    const updatedTotalLogs = [...updatedDayLogs, ...updatedNightLogs];

    const groupedTotalLogs = Object.values(
        updatedTotalLogs.reduce((acc, { hotelName }) => {
            if (!acc[hotelName]) {
                acc[hotelName] = { hotelName, count: 0 };
            }
            acc[hotelName].count += 1;
            return acc;
        }, {})
    );

    return (
        <div>
            <Card className="h-full w-full">
                <CardHeader variant="gradient" color="gray" className="mt-6 mb-8 p-6 flex flex-row justify-between">
                    <Typography variant="h6" color="white">
                        Attendance
                    </Typography>
                </CardHeader>
                <CardBody className="overflow-scroll px-0 pt-0 pb-2">
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="h-10 flex justify-between sm:justify-start">
                                <Typography className="mt-2">
                                    Employee:
                                </Typography>
                                <div className="flex flex-row">
                                    <select label="filterType" color="blue" className="ml-4 mb-1 bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                        name="filterType" id="filterType"
                                        onChange={(event) => setSelectedEmployee(event.target.value)}
                                        value={selectedEmployee}>
                                        <option key="" value="">Select</option>
                                        {employees.filter(emp => emp.primaryLocation.toLowerCase() === defaultHotel.toLowerCase()).map((e) => <option key={e.id} value={e.id}>{e.name}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Calendar assignments={assignments} dayData={attendanceDayLogs} nightData={attendanceNightLogs} onDateSelected={showLogAttendanceInternal} selectedMonth={selectedMonth} onMonthChange={(d) => handleMonthChange(d)} />
                    <div className="flex flex-wrap">
                        <div className="w-full lg:w-12/12 px-4 ml-30">
                            {groupedTotalLogs.map(group =>
                                <div key={group.hotelName} className="h-10 flex justify-between sm:justify-center">
                                    <Typography className="mt-2 uppercase">
                                        {group.hotelName}:
                                    </Typography>
                                    <Typography className="ml-2 mt-2 font-bold">
                                        {group.count}
                                    </Typography>
                                </div>)
                            }
                            <hr/>
                            <div className="h-10 flex justify-between sm:justify-center">
                                <Typography className="mt-2">
                                    Total Shifts:
                                </Typography>
                                <Typography className="ml-2 mt-2 font-bold">
                                    {getTotal(attendanceDayLogs, attendanceNightLogs)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
            <Dialog open={showLogAttendancePopup}>
                <DialogHeader>Log Attendance</DialogHeader>
                <DialogBody>
                    <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Employee Name <span className="text-red-500">*</span></label>
                            <input type="text" id="selectedEmployee" name="selectedEmployee"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={getEmployeeName(selectedEmployee)} disabled={true} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="attendanceLocation" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Worked in <span className="text-red-500">*</span></label>
                            <select color="blue" id="attendanceLocation" name="primaryLocation" label="attendanceLocation" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-3 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                onChange={(event) => setAttendanceLocation(event.target.value)} value={attendanceLocation}>
                                <option key="" value={""}>Select</option>
                                {assignments?.hotels.map((hotel) => (
                                    <option key={hotel.id} value={hotel.id}>{hotel.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Date  <span className="text-red-500">*</span></label>
                            <input type="text" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={attendanceDate} disabled={true} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="selectedEmployee" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Shift  <span className="text-red-500">*</span></label>
                            <input type="text" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={shift} disabled={true} />
                        </div>
                        <div className="w-50 mr-3 mt-3 mb-3">
                            <label htmlFor="remark" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Remarks</label>
                            <input type="text" className="border-0 ml-2 mr-2 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                value={remark} onChange={(event) => setRemark(event.target.value)} />
                        </div>
                    </div>
                </DialogBody>
                <DialogFooter>
                    <Button
                        variant="text"
                        color="red"
                        onClick={() => setShowLogAttendancePopup(false)}
                        className="mr-1"
                    >
                        <span>Cancel</span>
                    </Button>
                    <Button variant="gradient" color="green" onClick={logAttendanceInternal} className="mr-1">
                        <span>Save</span>
                    </Button>
                    {isAdmin && <Button variant="gradient" color="red" onClick={deleteAttendanceInternal} className="mr-1">
                        <span>Delete</span>
                    </Button>}
                </DialogFooter>
            </Dialog>
        </div>
    )
}
