import { Dialog, DialogBody, DialogFooter, DialogHeader, Button } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react';

const currentTimeIST = new Date().toLocaleTimeString('en-US', {
    timeZone: 'Asia/Kolkata',
    hour12: false,
    hour: '2-digit',
    minute: '2-digit'
});

export default function CheckoutSingle({ cardNo, roomNo, isOpen, onClose, onSubmit }) {
    const [date, setDate] = useState(formatDate);
    const [time, setTime] = useState(currentTimeIST);

    function formatDate() {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };



    let handleSubmitInternal = async () => {
        if (cardNo && roomNo && date && time) {
            await onSubmit({ cardNo, roomNo, date, time });
        }
    };

    let onCloseInternal = () => {
        onClose();
    };
    return (
        <Dialog open={isOpen}>
            <DialogHeader>Signle Checkout</DialogHeader>
            <DialogBody>
                <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="cardNo" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Card No</label>
                        <input type="number" id="amount" name="amount"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={cardNo} readOnly={true} disabled={true} />
                    </div>
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="roomNo" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Room No</label>
                        <input type="number" id="amount" name="amount"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={roomNo} readOnly={true} disabled={true} />
                    </div>
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="date" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Checkout Date</label>
                        <input type="date" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={date} onChange={(event) => setDate(event.target.value)} />
                    </div>
                    <div className="w-50 mr-3 mt-3 mb-3">
                        <label htmlFor="date" className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Checkout Date</label>
                        <input type="time" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={time} onChange={(event) => setTime(event.target.value)} />
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="red"
                    onClick={onCloseInternal}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={handleSubmitInternal}>
                    <span>Checkout</span>
                </Button>
            </DialogFooter>
        </Dialog>
    );
}