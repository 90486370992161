import { Button, Dialog, DialogBody, IconButton } from '@material-tailwind/react';
import { useFormik } from 'formik';
import React, { useEffect, useState, useRef } from 'react';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import * as Yup from 'yup';
import TimePicker from '../../components/time-picker';
import { useUserContext } from '../../context';
import { useApi } from '../../hooks/useApi';
import DatePicker from './../../components/date-picker';
import { MdDelete } from "react-icons/md";

export default function CheckIn() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { guestId, guestName } = state ?? { guestId: "", guestName: "" };
    const { getNextCardNo, searchGuest, getAvailableRooms, checkInRooms, uploadDocument } = useApi();
    const { fetchAccessToken, defaultHotel, user, assignments, isAdmin } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const [availableRooms, setAvailableRooms] = useState([]);
    const [roomCategories, setRoomCategories] = useState([]);
    const fileInputRef = useRef(null);
    const [attachments, setAttachments] = useState([]);

    const checkInFormik = useFormik({
        initialValues: {
            guestMobile: guestId,
            guestName: guestName,
            cardNo: 0,
            roomDetails: [],
            priceDetails: [],
            totalAmount: 0,
            totalDiscount: 0,
            paymentDetails: [],
            paymentReceived: 0,
            amountDue: 0,
            checkInDate: getCurrentDate(),
            checkInTime: getCurrentTime(),
            checkoutDate: getCurrentDate(1),
            checkoutTime: getCurrentTime(-1),
            bookingType: "",
            reference: "",
            totalDays: 1,
            documentS3Ids: []
        },
        validationSchema: Yup.object().shape({
            guestMobile: Yup.string().required('Guest mobile is required'),
            guestName: Yup.string().required('Guest name is required'),
            cardNo: Yup.number().required('Card No is required').positive('Card No must be a positive number'),
            roomDetails: Yup.array().of(
                Yup.object().shape({
                    roomNo: Yup.string().required('Room number is required'),
                    category: Yup.string().required('Room category is required'),
                    capacity_adults: Yup.number().required('Adults Capacity is required').positive('Adults Capacity must be an integer'),
                    unitDiscount: Yup.number().min(0, 'Discount must be zero or positive')
                })
            )
                .min(1, 'Room details cannot be empty'),
            paymentDetails: Yup.array().of(
                Yup.object().shape({
                    paymentMode: Yup.string().required('Payment mode is required'),
                    amount: Yup.number().required('Amount is required').positive('Amount must be a positive number')
                })
            ),
            paymentReceived: Yup.number().min(0, 'Payment received must be greater than or equal to 0'),
            checkInDate: Yup.date().required('Check-in date is required'),
            checkoutDate: Yup.date()
                .min(Yup.ref('checkInDate'), 'Checkout date must be after check-in date')
                .required('Checkout date is required'),
            totalDays: Yup.number().min(1, 'Room booking days must be greater than 0'),
            bookingType: Yup.string().required('Booking type is required'),
            reference: Yup.string().when(['bookingType'], {
                is: (bookingType) => bookingType === 'Online' || bookingType === 'Free',
                then: (schema) => schema.required('Reference is required for Online or Free booking type')
            }),
        }),
        onSubmit: async (values, actions) => {
            if (!areRoomNumbersUnique(values.roomDetails)) {
                alert("The same room number cannot be selected more than once.");
                return;
            }
            if (!isCheckoutAfterCheckin(values.checkInDate, values.checkoutDate)) {
                alert("Checkout date must be after check-in date.");
                return;
            }
            const isUPICard = values.paymentDetails.find(paymentDetail => paymentDetail.paymentMode === "UPI" || paymentDetail.paymentMode === "Card");
            if (isUPICard && values.documentS3Ids.length === 0) {
                alert("Please upload payment proofs");
                return;
            }
            try {
                let checkIns = [];
                let card = {
                    cardNo: values.cardNo,
                    hotelId: defaultHotel,
                    createdOn: Date.now(),
                    roomDetails: [],
                    paymentDetails: [],
                    totalAmount: values.totalAmount,
                    amountReceived: values.paymentReceived,
                    discount: 0,
                    amountDue: values.amountDue,
                    guestName: values.guestName,
                    guestMobile: values.guestMobile,
                    status: "ACTIVE",
                    dueReason: '',
                    documentS3Ids: values.documentS3Ids,
                    bookingType: values.bookingType,
                    reference: values.reference
                };
                values.paymentDetails.forEach(paymentDetail => {
                    card.paymentDetails.push({
                        paymentMode: paymentDetail.paymentMode,
                        paymentAmount: paymentDetail.amount,
                        paymentDateTime: paymentDetail.receivedOn,
                        isNew: true
                    });
                });
                values.roomDetails.forEach((roomDetail, index) => {
                    const priceDetail = values.priceDetails.find(pd => pd.roomNo === roomDetail.roomNo);
                    card.roomDetails.push({
                        roomNo: roomDetail.roomNo,
                        category: priceDetail.category,
                        unitDiscount: roomDetail.unitDiscount,
                        fare: priceDetail.fare,
                        extraBedCost: roomDetail.extraBedCost,
                        gst: priceDetail.gst,
                        adults: roomDetail.capacity_adults,
                        kids: roomDetail.capacity_kids,
                        extraBeds: roomDetail.extraBeds,
                        checkInDateTime: combineDateAndTime(values.checkInDate, values.checkInTime),
                        checkOutDateTime: combineDateAndTime(values.checkoutDate, values.checkoutTime),
                        totalDays: values.totalDays,
                        totalAmount: priceDetail.totalCost
                    });
                });
                values.roomDetails.forEach((roomDetail, index) => {
                    checkIns.push({
                        hotelId: defaultHotel,
                        roomNo: roomDetail.roomNo,
                        checkInEpoch: getEpoch(values.checkInDate, values.checkInTime, index + 1),
                        checkOutEpoch: getEpoch(values.checkoutDate, values.checkoutTime, index + 1),
                        checkInDateTime: combineDateAndTime(values.checkInDate, values.checkInTime),
                        checkOutDateTime: combineDateAndTime(values.checkoutDate, values.checkoutTime),
                        cardNo: values.cardNo,
                        guestName: values.guestName,
                        guestMobile: values.guestMobile,
                        manager: user.name,
                        bookingType: values.bookingType,
                        category: roomDetail.category
                    });
                });
                let checkInCard = { card, checkIns };
                console.log(JSON.stringify(checkInCard));
                setIsLoading(true);
                const token = await fetchAccessToken();
                await checkInRooms(defaultHotel, checkInCard, values.cardNo, token);
                alert("Guests has been Checked-In.");
                navigate("/manager/dashboard");
            } catch (ex) {
                alert("Error occurred while checking guests." + ex);
            } finally {
                setIsLoading(false);
            }

        }
    });

    let handleSubmitInternal = () => {
        checkInFormik.handleSubmit();
    };

    function getEpoch(date, time, seconds) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);
        combinedDate.setSeconds(seconds);
        combinedDate.setMilliseconds(seconds * 100);

        return combinedDate.getTime();
    }

    function combineDateAndTime(date, time) {
        const [hours, minutes] = time.split(':').map(Number);
        const combinedDate = new Date(date);
        combinedDate.setHours(hours);
        combinedDate.setMinutes(minutes);

        const formattedDateTime = `${combinedDate.getDate().toString().padStart(2, '0')}-${(combinedDate.getMonth() + 1).toString().padStart(2, '0')}-${combinedDate.getFullYear()} ${combinedDate.getHours().toString().padStart(2, '0')}:${combinedDate.getMinutes().toString().padStart(2, '0')}`;
        return formattedDateTime;
    }

    function areRoomNumbersUnique(roomDetails) {
        const roomNumbers = new Set();
        for (const room of roomDetails) {
            if (room.roomNo !== "" && roomNumbers.has(room.roomNo)) {
                return false;
            }
            roomNumbers.add(room.roomNo);
        }
        return true;
    }

    function isCheckoutAfterCheckin(checkInDate, checkoutDate) {
        const checkInDateWithoutTime = new Date(checkInDate.getFullYear(), checkInDate.getMonth(), checkInDate.getDate());
        const checkoutDateWithoutTime = new Date(checkoutDate.getFullYear(), checkoutDate.getMonth(), checkoutDate.getDate());
        return checkoutDateWithoutTime > checkInDateWithoutTime;
    }

    let handleAddRoom = () => {
        checkInFormik.setFieldValue("roomDetails", [...checkInFormik.values.roomDetails, {
            roomNo: "",
            category: "",
            capacity_adults: 0,
            capacity_kids: 0,
            extraBeds: 0,
            unitDiscount: 0
        }]);
    };

    let handleDeleteRoom = (index) => {
        const updatedRoomDetails = [...checkInFormik.values.roomDetails];
        updatedRoomDetails.splice(index, 1);
        checkInFormik.setFieldValue("roomDetails", updatedRoomDetails);
    };

    let handleDeletePayment = (index) => {
        const updatedPaymentDetails = [...checkInFormik.values.paymentDetails];
        updatedPaymentDetails.splice(index, 1);
        checkInFormik.setFieldValue("paymentDetails", updatedPaymentDetails);
    };

    let handleAddPayment = () => {
        checkInFormik.setFieldValue("paymentDetails", [...checkInFormik.values.paymentDetails, {
            paymentMode: "",
            amount: 0,
            receivedOn: Date.now()
        }]);
    };

    const handleRoomNoChange = (index, event) => {
        checkInFormik.handleChange(event);
    };

    const handleCategoryChange = (index, event) => {
        checkInFormik.handleChange(event);
    };

    let onCheckInDateChange = (date) => {
        checkInFormik.setFieldValue('checkInDate', date);
    };

    let onCheckInTimeChange = (time) => {
        checkInFormik.setFieldValue('CheckInTime', time);
    };

    let onCheckoutDateChange = (date) => {
        checkInFormik.setFieldValue('checkoutDate', date);
        const differenceDays = (date - checkInFormik.values.checkInDate) / (1000 * 60 * 60 * 24);
        checkInFormik.setFieldValue('totalDays', differenceDays);

    };

    let onCheckoutTimeChange = (time) => {
        checkInFormik.setFieldValue('checkoutTime', time);
    };

    let handleSearchGuestInternal = async () => {
        let guestMobile = checkInFormik.values.guestMobile;
        if (guestMobile && isValidMobileNumber(guestMobile)) {
            try {
                setIsLoading(true);
                const token = await fetchAccessToken();
                const guest = await searchGuest(defaultHotel, guestMobile, token);
                if (guest) {
                    checkInFormik.setFieldValue('guestName', guest.name);
                }
            } catch (ex) {

            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleGenerateCardNo = async () => {
        if (!defaultHotel) {
            return;
        }
        setIsLoading(true);
        try {
            const token = await fetchAccessToken();
            let data = await getNextCardNo(defaultHotel, token);
            if (data) {
                checkInFormik.setFieldValue('cardNo', data.nextCardNo);
            }
        } catch (error) {
            console.error('Error fetching data:', error.message);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getNextCardNoAsync = async (hotelId) => {
            setIsLoading(true);
            try {
                const token = await fetchAccessToken();
                let data = await getNextCardNo(hotelId, token);
                checkInFormik.setFieldValue('cardNo', data.nextCardNo);
                let freeRooms = await getAvailableRooms(hotelId, token);
                setAvailableRooms(freeRooms);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (defaultHotel) {
            let hotel = assignments?.hotels.find(hotel => hotel.id.toLowerCase() === defaultHotel.toLowerCase());
            setRoomCategories(hotel.roomTypes);
            getNextCardNoAsync(defaultHotel);
        }
    }, [defaultHotel]);

    useEffect(() => {
        if (checkInFormik.values.bookingType === "Online") {
            checkInFormik.setFieldValue('checkoutTime', "11:00");
        } else {
            checkInFormik.setFieldValue('checkoutTime', getCurrentTime(-1));
        }
    }, [checkInFormik.values.bookingType]);

    function isValidMobileNumber(number) {
        const regex = /^[0-9]{10}$/;
        return regex.test(number);
    }

    function getCurrentTime(hoursAdd = 0) {
        const now = new Date();
        const hours = String(now.getHours() + hoursAdd).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return hours + ':' + minutes;
    }

    function getCurrentDate(daysAdd = 0) {
        let d = new Date();
        d.setDate(d.getDate() + daysAdd);
        d.setHours(0, 0, 0, 0)
        return d;
    }

    function OptionsGenerator(n) {
        const options = [];
        for (let i = 1; i <= n; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }

        return options;
    }

    useEffect(() => {
        const { roomDetails, totalDays, paymentDetails } = checkInFormik.values;
        let priceDetails = [];
        let totalAmount = 0;
        let totalDiscount = 0;
        roomDetails.forEach(function (roomDetail) {
            if (roomDetail.category) {
                const rc = getRoomDetailsByCategory(roomDetail.category);
                const priceDetail = {};
                priceDetail.roomNo = roomDetail.roomNo;
                priceDetail.category = rc.category;
                priceDetail.fare = totalDays * rc.price;
                priceDetail.extraBedCost = totalDays * roomDetail.extraBeds * rc.extraBedCharges;
                priceDetail.gst = ((totalDays * rc.price) + priceDetail.extraBedCost) * (rc.gst / 100);
                priceDetail.totalCost = priceDetail.fare + priceDetail.extraBedCost + priceDetail.gst;
                totalAmount += priceDetail.totalCost;
                totalDiscount += totalDays * roomDetail.unitDiscount;
                priceDetails.push(priceDetail);
            }
        });
        const paymentReceived = paymentDetails.reduce((accumulator, current) => {
            return accumulator + current.amount;
        }, 0);
        totalAmount = totalAmount - totalDiscount;
        checkInFormik.setFieldValue("priceDetails", priceDetails);
        checkInFormik.setFieldValue("totalAmount", totalAmount);
        checkInFormik.setFieldValue("totalDiscount", totalDiscount);
        checkInFormik.setFieldValue("paymentReceived", paymentReceived);
        checkInFormik.setFieldValue("amountDue", totalAmount - paymentReceived);
    }, [checkInFormik.values.roomDetails, checkInFormik.values.totalDays, checkInFormik.values.paymentDetails]);


    function getRoomDetailsByCategory(category) {
        const rc = roomCategories.find(rc => rc.category === category);
        return rc;
    }

    function convertBlobToBase64(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    let handleUploadAttachments = async () => {
        try {
            if (attachments.length < 1) {
                alert("Please capture attachments");
                return;
            }
            setIsLoading(true);
            const token = await fetchAccessToken();
            const responses = await Promise.all(attachments.map(async (attachment) => {
                let base64String = await convertBlobToBase64(attachment);
                return await uploadDocument(base64String, token);
            }));
            checkInFormik.setFieldValue('documentS3Ids', responses);
            setAttachments([]);
        } catch (ex) {
            alert("Error occurred while uploading proofs.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteAttachment = (index) => {
        const newItems = attachments.filter((_, i) => i !== index);
        setAttachments(newItems);
    };

    const handleFileChange = (e) => {
        setIsLoading(true);
        let file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png')) {
            compressImage(file, (compressedBlob) => {
                setAttachments([...attachments, compressedBlob]);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
            alert('Please upload a JPEG or PNG image.');
        }
    };

    const compressImage = (file, callback) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const img = new Image();
            img.src = e.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                const fileType = file.type;
                const isJPEG = fileType === 'image/jpeg' || fileType === 'image/jpg';

                let quality = isJPEG ? 1.0 : undefined;
                const maxFileSize = 1000000; // 1MB

                const compress = () => {
                    canvas.toBlob((blob) => {
                        if (blob.size <= maxFileSize || (isJPEG && quality < 0.1)) {
                            callback(blob);
                        } else if (isJPEG) {
                            quality -= 0.1;
                            compress();
                        } else {
                            // For PNG, if the first compression is too large, we can't reduce quality, so we just use the first result
                            callback(blob);
                        }
                    }, fileType, quality);
                };

                compress();
            };
        };
        reader.readAsDataURL(file);
    };

    return (
        <div>
            <section className="py-1 bg-blueGray-50">
                <div className="w-full lg:w-10/12 px-4 mx-auto mt-6">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                        <div className="rounded-t bg-white mb-0 px-6 py-6">
                            <div className="text-center flex justify-between">
                                <h6 className="text-blueGray-700 text-xl font-bold">
                                    Check In
                                </h6>
                            </div>
                        </div>
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                            <div className="flex flex-wrap mt-3">
                                <div className="flex flex-row w-full mb-3 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Card No<span className="text-red-500">*</span>
                                        </label>
                                        <input type="number" id="cardNo" name="cardNo"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-150 ease-linear transition-all duration-150"
                                            value={checkInFormik.values.cardNo} readOnly={true} />
                                        <Button className="rounded-full mt-2 ml-3" size="sm" onClick={handleGenerateCardNo}>Generate</Button>
                                        {checkInFormik.touched.cardNo && checkInFormik.errors.cardNo ? (
                                            <div className="text-red-500">{checkInFormik.errors.cardNo}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="flex flex-row w-full mb-3 px-4">

                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Guest Mobile<span className="text-red-500">*</span>
                                        </label>
                                        <input type="tel" id="guestMobile" name="guestMobile" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={checkInFormik.values.guestMobile} onChange={checkInFormik.handleChange} onBlur={checkInFormik.handleBlur} placeholder="Enter guest mobile number" />
                                        {checkInFormik.touched.guestMobile && checkInFormik.errors.guestMobile ? (
                                            <div className="text-red-500">{checkInFormik.errors.guestMobile}</div>
                                        ) : null}
                                        <Button className="rounded-full mt-2" size="sm" onClick={handleSearchGuestInternal}>Search</Button>
                                    </div>
                                    <div className="relative w-full mb-3 ml-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Guest Name<span className="text-red-500">*</span>
                                        </label>
                                        <input type="text" id="guestName" name="guestName" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            readOnly={true} value={checkInFormik.values.guestName} placeholder="Enter full name" />
                                        {checkInFormik.touched.guestName && checkInFormik.errors.guestName ? (
                                            <div className="text-red-500">{checkInFormik.errors.guestName}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Check-In Date<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex flex-row">
                                            <DatePicker defaultDate={checkInFormik.values.checkInDate} onDateSelect={onCheckInDateChange} isDisabled={!isAdmin} />
                                            <TimePicker defaultTime={checkInFormik.values.checkInTime} onTimeChange={onCheckInTimeChange} isDisabled={!isAdmin} />
                                        </div>
                                        {checkInFormik.touched.checkInDate && checkInFormik.errors.checkInDate ? (
                                            <div className="text-red-500">{checkInFormik.errors.checkInDate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Checkout Date<span className="text-red-500">*</span>
                                        </label>
                                        <div className="flex flex-row">
                                            <DatePicker defaultDate={checkInFormik.values.checkoutDate} onDateSelect={onCheckoutDateChange} />
                                            <TimePicker defaultTime={checkInFormik.values.checkoutTime} onTimeChange={onCheckoutTimeChange} isDisabled={!isAdmin} />
                                        </div>
                                        {checkInFormik.touched.checkoutDate && checkInFormik.errors.checkoutDate ? (
                                            <div className="text-red-500">{checkInFormik.errors.checkoutDate}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-6/12 px-4">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Booking Type<span className="text-red-500">*</span>
                                        </label>
                                        <select label="Booking Type" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                            name="bookingType" id="bookingType"
                                            onChange={checkInFormik.handleChange}
                                            onBlur={checkInFormik.handleBlur}
                                            value={checkInFormik.values.bookingType}>
                                            <option key="" value="">Select</option>
                                            <option key="Walk-In" value="Walk-In">Walk-In</option>
                                            <option key="Online" value="Online">Online</option>
                                            <option key="Free" value="Free">Free</option>
                                            <option key="Couple" value="Couple">Couple</option>
                                        </select>
                                        {checkInFormik.touched.bookingType && checkInFormik.errors.bookingType ? (
                                            <div className="text-red-500">{checkInFormik.errors.bookingType}</div>
                                        ) : null}
                                    </div>
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                        {checkInFormik.values.bookingType === "Online" ? "Booking Id" : "Reference By"} 
                                        </label>
                                        <input type="text" id="reference" name="reference" className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            value={checkInFormik.values.reference} onChange={checkInFormik.handleChange} onBlur={checkInFormik.handleBlur} placeholder="Enter reference" />
                                        {checkInFormik.touched.reference && checkInFormik.errors.reference ? (
                                            <div className="text-red-500">{checkInFormik.errors.reference}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="w-full lg:w-12/12 px-4 rounded ">
                                    <div className="relative w-full mb-3">
                                        <label className="uppercase text-blueGray-600 text-xs font-bold mb-2 flex flex-row" >
                                            Room Details
                                        </label>
                                        {typeof checkInFormik.errors.roomDetails === 'string' && checkInFormik.errors.roomDetails !== undefined ? (
                                            <div className="text-red-500">{checkInFormik.errors.roomDetails}</div>
                                        ) : null}
                                        <div className="w-full lg:w-12/12 px-1  py-0 flex justify-end ">
                                            <IconButton color="green" onClick={handleAddRoom}>
                                                <IoIosAddCircleOutline className="w-5 h-5" />
                                            </IconButton>
                                        </div>
                                        {checkInFormik.values.roomDetails.map((roomDetail, index) =>
                                            <div className="flex flex-wrap border border-gray-300 p-2" key={index}>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.roomNo`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Room<span className="text-red-500">*</span></label>
                                                    <select label="Room" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`roomDetails.${index}.roomNo`} id={`roomDetails.${index}.roomNo`}
                                                        onChange={(event) => handleRoomNoChange(index, event)}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={roomDetail.roomNo}>
                                                        <option value="">Select</option>
                                                        {availableRooms.sort((a, b) => a.roomNo.localeCompare(b.roomNo)).map((room, i) => (
                                                            <option key={i} value={room.roomNo}>{room.roomNo}</option>
                                                        ))}
                                                    </select>
                                                    {checkInFormik.touched.roomDetails && checkInFormik.touched.roomDetails[index] && checkInFormik.errors.roomDetails && checkInFormik.errors.roomDetails[index] && checkInFormik.errors.roomDetails[index].roomNo ? (
                                                        <div className="text-red-500">{checkInFormik.errors.roomDetails[index].roomNo}</div>
                                                    ) : null}
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.category`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Category Room<span className="text-red-500">*</span></label>
                                                    <select label="Category" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`roomDetails.${index}.category`} id={`roomDetails.${index}.category`}
                                                        onChange={(event) => handleCategoryChange(index, event)}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={roomDetail.category}>
                                                        <option value="">Select</option>
                                                        {roomCategories.map((category, i) => (
                                                            <option key={i} value={category.category}>{category.category}</option>
                                                        ))}
                                                    </select>
                                                    {checkInFormik.touched.roomDetails && checkInFormik.touched.roomDetails[index] && checkInFormik.errors.roomDetails && checkInFormik.errors.roomDetails[index] && checkInFormik.errors.roomDetails[index].roomNo ? (
                                                        <div className="text-red-500">{checkInFormik.errors.roomDetails[index].roomNo}</div>
                                                    ) : null}
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.capacity_adults`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Adults<span className="text-red-500">*</span></label>
                                                    <select label="Adults" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`roomDetails.${index}.capacity_adults`} id={`roomDetails.${index}.capacity_adults`}
                                                        onChange={checkInFormik.handleChange}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={roomDetail.capacity_adults}>
                                                        <option value={0}>Select</option>
                                                        {roomCategories.find(rc => rc.category === roomDetail.category) &&
                                                            OptionsGenerator(roomCategories.find(rc => rc.category === roomDetail.category).capacity.adults)
                                                        }
                                                    </select>
                                                    {checkInFormik.touched.roomDetails && checkInFormik.touched.roomDetails[index] && checkInFormik.errors.roomDetails && checkInFormik.errors.roomDetails[index] && checkInFormik.errors.roomDetails[index].capacity_adults ? (
                                                        <div className="text-red-500">{checkInFormik.errors.roomDetails[index].capacity_adults}</div>
                                                    ) : null}
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.capacity_kids`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Kids</label>
                                                    <select label="Kids" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`roomDetails.${index}.capacity_kids`} id={`roomDetails.${index}.capacity_kids`}
                                                        onChange={checkInFormik.handleChange}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={roomDetail.capacity_kids}>
                                                        <option value={0}>Select</option>
                                                        {roomCategories.find(rc => rc.category === roomDetail.category) &&
                                                            OptionsGenerator(roomCategories.find(rc => rc.category === roomDetail.category).capacity.kids)
                                                        }
                                                    </select>
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.extraBeds`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Extra Beds</label>
                                                    <select label="Extra Beds" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`roomDetails.${index}.extraBeds`} id={`roomDetails.${index}.extraBeds`}
                                                        onChange={checkInFormik.handleChange}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={roomDetail.extraBeds}>
                                                        <option key={0} value={0}>Select</option>
                                                        <option key={1} value={1}>1</option>
                                                        <option key={2} value={2}>2</option>
                                                        <option key={3} value={3}>3</option>
                                                    </select>
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`roomDetails.${index}.unitDiscount`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Discount(Per Day)</label>
                                                    <input type="number" id={`roomDetails.${index}.unitDiscount`} name={`roomDetails.${index}.unitDiscount`}
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={roomDetail.unitDiscount} onChange={checkInFormik.handleChange} onBlur={checkInFormik.handleBlur} />
                                                    {checkInFormik.touched.roomDetails && checkInFormik.touched.roomDetails[index] && checkInFormik.errors.roomDetails && checkInFormik.errors.roomDetails[index] ? (
                                                        <div className="text-red-500">{checkInFormik.errors.roomDetails[index].unitDiscount}</div>
                                                    ) : null}
                                                </div>
                                                {index !== 0 && <div>
                                                    <IconButton color="red" className="mr-3 mt-9"
                                                        onClick={() => handleDeleteRoom(index)}>
                                                        <IoMdRemoveCircleOutline className="w-5 h-5" />
                                                    </IconButton>
                                                </div>
                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="w-full lg:w-12/12 px-4 overflow-scroll">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Price Details
                                        </label>
                                        <table className="min-w-full divide-y divide-gray-200 bg-white shadow overflow-hidden rounded-lg">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room No</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Type</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Room Fare</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Extra Bed Cost</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">GST (12%)</th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Cost</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {checkInFormik.values.priceDetails.map((priceDetail, i) => <tr>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.roomNo}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.category}</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.fare}.00</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.extraBedCost}.00</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.gst}.00</td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-gray-700">{priceDetail.totalCost}.00</td>
                                                </tr>)}
                                                <tr className="bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={5} >Total Total</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{checkInFormik.values.totalAmount + checkInFormik.values.totalDiscount}.00</td>
                                                </tr>
                                                <tr className="bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={5} >Discount</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{checkInFormik.values.totalDiscount}.00</td>
                                                </tr>
                                                <tr className="bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={5} >Net Amount</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{checkInFormik.values.totalAmount}.00</td>
                                                </tr>
                                                <tr className="bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={5} >Amount Received</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{checkInFormik.values.paymentReceived}.00</td>
                                                </tr>
                                                <tr className="bg-gray-50">
                                                    <td className="px-6 py-4 whitespace-nowrap text-right" colSpan={5} >Balance Due</td>
                                                    <td className="px-6 py-4 whitespace-nowrap">{checkInFormik.values.amountDue}.00</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="w-full lg:w-12/12 px-4 py-2">
                                    <div className="relative w-full mb-3">
                                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                            Advance Payment
                                        </label>
                                        {typeof checkInFormik.errors.paymentDetails === 'string' && checkInFormik.errors.paymentDetails !== undefined ? (
                                            <div className="text-red-500">{checkInFormik.errors.paymentDetails}</div>
                                        ) : null}
                                        <div className="w-full lg:w-12/12 px-1  py-0 flex justify-end ">
                                            <IconButton color="green" onClick={handleAddPayment}>
                                                <IoIosAddCircleOutline className="w-5 h-5" />
                                            </IconButton>
                                        </div>
                                        {checkInFormik.values.paymentDetails.map((paymentDetail, index) =>
                                            <div className="flex flex-wrap lg:justify-start sm:flex-wrap p-2" >
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`paymentDetails.${index}.paymentMode`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Payment Type <span className="text-red-500">*</span></label>
                                                    <select label="Payment Type" color="blue" className="bg-white text-gray-900 border border-gray-300 rounded-md py-2 pl-3 pr-10 focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                                                        name={`paymentDetails.${index}.paymentMode`} id={`paymentDetails.${index}.paymentMode`}
                                                        onChange={checkInFormik.handleChange}
                                                        onBlur={checkInFormik.handleBlur}
                                                        value={paymentDetail.paymentMode}>
                                                        <option key="" value="">Select</option>
                                                        <option key="Cash" value="Cash">Cash</option>
                                                        <option key="UPI" value="UPI">UPI</option>
                                                        <option key="Card" value="Card">Card</option>
                                                    </select>
                                                    {checkInFormik.touched.paymentDetails && checkInFormik.touched.paymentDetails[index] && checkInFormik.errors.paymentDetails && checkInFormik.errors.paymentDetails[index] ? (
                                                        <div className="text-red-500">{checkInFormik.errors.paymentDetails[index].paymentMode}</div>
                                                    ) : null}
                                                </div>
                                                <div className="w-50 mr-3 mt-3 mb-3">
                                                    <label htmlFor={`paymentDetails.${index}.amount`} className="block uppercase text-blueGray-600 text-xs font-bold mb-2">Amount<span className="text-red-500">*</span></label>
                                                    <input type="number" id={`paymentDetails.${index}.amount`} name={`paymentDetails.${index}.amount`}
                                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                        value={paymentDetail.amount} onChange={checkInFormik.handleChange} onBlur={checkInFormik.handleBlur} />
                                                    {checkInFormik.touched.paymentDetails && checkInFormik.touched.paymentDetails[index] && checkInFormik.errors.paymentDetails && checkInFormik.errors.paymentDetails[index] ? (
                                                        <div className="text-red-500">{checkInFormik.errors.paymentDetails[index].amount}</div>
                                                    ) : null}
                                                </div>
                                                <div>
                                                    <IconButton color="red" className="mr-3 mt-9"
                                                        onClick={() => handleDeletePayment(index)}>
                                                        <IoMdRemoveCircleOutline className="w-5 h-5" />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-wrap">
                                        <div className="relative w-full mb-3">
                                            <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2" >
                                                Payment proofs
                                            </label>
                                            <div className="flex flex-col">
                                                <div className="flex flex-col lg:w-4/12">
                                                    <div className="py-3">
                                                        <input type="file" accept="image/*" onChange={handleFileChange} ref={fileInputRef} />
                                                    </div>
                                                </div>
                                                <div className="relative w-full px-5">
                                                    {
                                                        attachments.map((image, i) =>
                                                            <div className="flex flex-row">
                                                                <img key={i} src={URL.createObjectURL(image)} style={{ height: "100px", width: "100px", margin: "5px" }} />
                                                                <IconButton variant="text" onClick={() => handleDeleteAttachment(i)}>
                                                                    <MdDelete className="h-6 w-6" />
                                                                </IconButton>
                                                            </div>
                                                        )
                                                    }
                                                    <ul>
                                                        {checkInFormik.values.documentS3Ids.map((s3Id, i) => <li className="cursor-pointer"><u key={i}>{"Payment Proof "} {i + 1}</u></li>)}
                                                    </ul>
                                                    {checkInFormik.touched.attachments && checkInFormik.errors.attachments ? (
                                                        <div className="text-red-500">{checkInFormik.errors.attachments}</div>
                                                    ) : null}

                                                    <button className="shadow bg-blue-500 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white font-bold text-xs py-2 px-4  mr-2 rounded" type="button"
                                                        onClick={handleUploadAttachments}>
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end  mb-0 px-6 py-6">
                            <button className="bg-pink-500 text-white hover:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-2 ease-linear transition-all duration-150" type="button" onClick={handleSubmitInternal}>
                                Check-In
                            </button>
                        </div>
                    </div>

                </div>
            </section >
            <Dialog open={isLoading} className="bg-transparent shadow-none" size="md">
                <DialogBody className="bg-transparent shadow-none flex justify-center">
                    <ClockLoader color="#2be60d" />
                </DialogBody>
            </Dialog>
        </div >
    )
}
